import { useState } from 'react';
// Components/ui
import { MenuItem, Box, Typography, useTheme } from '@mui/material';
import { CustomTooltip } from '../../style/CustomTooltip';
import { style } from './style';
// Types
import { TemplatesList } from 'context/Templates/types/Lists';

type TemplateItemProps = {
  template: TemplatesList;
  setSelectedTemplate: (selectedValue: TemplatesList) => void;
  selectedTemplateIndex: number;
  setSelectedTemplateIndex: (index: number) => void;
  setEditedMessage: (newMessage: string) => void;
  setFocusType: (focusType: 'templateList' | 'preview' | null) => void;
  setIsEditing: (isEditing: boolean) => void;
  index: number;
};
const TemplateItem = ({
  template,
  setSelectedTemplate,
  selectedTemplateIndex,
  setSelectedTemplateIndex,
  setEditedMessage,
  setFocusType,
  setIsEditing,
  index,
}: TemplateItemProps) => {
  const theme = useTheme();

  const [openTooltip, setOpenTooltip] = useState<string>('');

  const handleOpenTooltip = (id: string) => {
    setOpenTooltip(id);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip('');
  };

  const onSelect = (selectedValue: TemplatesList, index: number) => {
    setSelectedTemplate(selectedValue);
    setEditedMessage(selectedValue?.content?.message || '');
    setFocusType('templateList');
    setIsEditing(true);
    setSelectedTemplateIndex(index);
  };

  return (
    <MenuItem
      selected={selectedTemplateIndex === index}
      onMouseEnter={() => handleOpenTooltip(template.name)}
      onClick={() => onSelect(template, index)}
      onKeyDown={() => onSelect(template, index)}
      onKeyUp={() => onSelect(template, index)}
      onFocus={() => setSelectedTemplateIndex(index)}
      onMouseLeave={handleCloseTooltip}
      onBlur={handleCloseTooltip}
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
      tabIndex={index === 0 ? 0 : selectedTemplateIndex === index ? 0 : index + 1}
    >
      <CustomTooltip
        title={template.name}
        placement="top"
        open={openTooltip === template.name}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant="body1" noWrap sx={style.typography}>
            {template.name}
          </Typography>
          <Typography variant="body2" noWrap sx={style.typography}>
            {template.category}
          </Typography>
        </Box>
      </CustomTooltip>
    </MenuItem>
  );
};

export default TemplateItem;
