import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
import { CampaignSliceState } from './types/CampaignSliceState';
// Service
import campaignSliceService from './campaignSliceService';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ICampaign } from '@trii/types/dist/Marketing';
import { RootState } from 'redux/store';


const initialState: CampaignSliceState = {
    campaigns: [],
    status: {
        campaigns: 'idle'
    },
    filterStatus: 'design',
    filterType: null,
    filterRange: null,
    filterFromDate: null,
    filterToDate: null 
};


export const fetchCampaigns = createAsyncThunk<
    ICampaign[],
    { status: string, type: number, range: number, fromDate: number, toDate:number },
    { state: RootState }
>('Campaign/fetchCampaigns', async ({ status, type, range, fromDate, toDate }, { dispatch }) => {
    const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

    const campaigns = await campaignSliceService.fetchCampaigns(jwtToken, URL_MARKETING, status, type, range, fromDate, toDate);

    return campaigns;
});

export const fetchPauseCampaign = createAsyncThunk<
    string,
    string,
    { state: RootState }
>(
    'Campaign/fetchPauseCampaign',
    async (campaignId, { dispatch, getState }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

        await campaignSliceService.fetchPauseCampaign(jwtToken, URL_MARKETING, campaignId);

        return campaignId;
    }
);

export const fetchResumeCampaign = createAsyncThunk<
    string,
    string,
    { state: RootState }
>(
    'Campaign/fetchResumeCampaign',
    async (campaignId, { dispatch, getState }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

        await campaignSliceService.fetchResumeCampaign(jwtToken, URL_MARKETING, campaignId);

        return campaignId;
    }
);

export const fetchCancelCampaign = createAsyncThunk<
    string,
    string,
    { state: RootState }
>(
    'Campaign/fetchCancelCampaign',
    async (campaignId, { dispatch }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

        await campaignSliceService.cancelCampaignSend(jwtToken, URL_MARKETING, campaignId);

        return campaignId;
    }
);

export const fetchDeleteCampaign = createAsyncThunk<
    string,
    string,
    { state: RootState }
>(
    'Campaign/fetchDeleteCampaign',
    async (campaignId, { dispatch }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;
        await campaignSliceService.deleteCampaign(jwtToken, URL_MARKETING, campaignId);
        return campaignId;
    }
);

export const fetchCloneCampaign = createAsyncThunk<
    ICampaign,
    string,
    { state: RootState }
>(
    'Campaign/fetchCloneCampaign',
    async (campaignId, { dispatch }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;
        const newCampaign = await campaignSliceService.cloneCampaign(jwtToken, URL_MARKETING, campaignId);

        // await dispatch(fetchCampaigns(1));
        return newCampaign;
    }
);

export const campaignSlice = createSlice({
    name: 'Campaign',
    initialState,
    reducers: {
        setCampaigns: (state, action: PayloadAction<ICampaign[]>) => {
            state.campaigns = action.payload.map(campaign => ({
                ...campaign,
                paused: campaign.paused ?? false,
            }));
        },
        setFilterStatus: (state, action: PayloadAction<string>) => {
            state.filterStatus = action.payload;
        },
        setFilterType: (state, action: PayloadAction<number>) => {
            state.filterType = action.payload; 
        },
        setFilterRange: (state, action: PayloadAction<number>) => {
            state.filterRange = action.payload; 
        },
        setFilterFromDate: (state, action: PayloadAction<number>) => {
            state.filterFromDate = action.payload; 
        },
        setFilterToDate: (state, action: PayloadAction<number>) => {
            state.filterToDate = action.payload; 
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCampaigns.pending, (state) => {
                state.status.campaigns = 'loading';
            })
            .addCase(fetchCampaigns.fulfilled, (state, action: PayloadAction<ICampaign[]>) => {
                console.log('Todos los estados de las campañas:', action.payload.map(campaign => campaign.status));
                state.status.campaigns = 'succeeded';
                state.campaigns = action.payload.map(campaign => ({
                    ...campaign,
                    paused: campaign.paused ?? false,
                }));
            })
            .addCase(fetchCampaigns.rejected, (state) => {
                state.status.campaigns = 'rejected';
            })

            // Pausar campaña
            .addCase(fetchPauseCampaign.fulfilled, (state, action: PayloadAction<string>) => {
                const campaign = state.campaigns.find(c => c.id === action.payload);
                if (campaign) {
                    campaign.paused = true;
                }
            })

            // Reanudar campaña
            .addCase(fetchResumeCampaign.fulfilled, (state, action: PayloadAction<string>) => {
                const campaign = state.campaigns.find(c => c.id === action.payload);
                if (campaign) {
                    campaign.paused = false;
                }
            })

             // Cancelar envío de campaña
            .addCase(fetchCancelCampaign.fulfilled, (state, action: PayloadAction<string>) => {
                const campaign = state.campaigns.find(c => c.id === action.payload);
                if (campaign) {
                    campaign.status = 5; 
                }
                state.status.campaigns = 'succeeded';
                console.log('Campaña cancelada con éxito:', action.payload);
            })

            // Eliminar campaña (nuevo bloque)
            .addCase(fetchDeleteCampaign.pending, (state) => {
                state.status.campaigns = 'loading';
            })
            .addCase(fetchDeleteCampaign.fulfilled, (state, action: PayloadAction<string>) => {
                const campaign = state.campaigns.find(c => c.id === action.payload);
                if (campaign) {
                    campaign.status = 0;
                }
                state.campaigns = state.campaigns.filter(campaign => campaign.id !== action.payload);
                state.status.campaigns = 'succeeded';
                console.log('Campaña eliminada con éxito:', action.payload);
            })
            .addCase(fetchDeleteCampaign.rejected, (state) => {
                state.status.campaigns = 'rejected';
                console.error('Error al eliminar la campaña');
            })

            .addCase(fetchCloneCampaign.pending, (state) => {
                state.status.campaigns = 'loading';
            })
            .addCase(fetchCloneCampaign.fulfilled, (state, action: PayloadAction<ICampaign>) => {
                state.status.campaigns = 'succeeded';
                state.campaigns.push(action.payload);
                console.log('Campaña clonada:', action.payload);
            })
            .addCase(fetchCloneCampaign.rejected, (state) => {
                state.status.campaigns = 'rejected';
                console.error('Error al clonar la campaña');
            });
    },
});



// Selectores
const campaignState = (state: RootState) => state.Campaign;

export const selectCampaigns = createSelector(
    campaignState,
    (campaign) => campaign.campaigns
);

export const selectCampaignStatus = createSelector(
    campaignState,
    (campaign) => campaign.status
);

export const selectCampaignType = createSelector(
    campaignState,
    (campaign) => campaign.type
);

export const selectCampaignRange= createSelector(
    campaignState,
    (campaign) => campaign.range
);

export const selectFilterStatus = createSelector(
    campaignState,
    (campaign) => campaign.filterStatus
);

export const selectFilterType = createSelector(
    campaignState,
    (campaign) => campaign.filterType
);

export const selectFilterRange = createSelector(
    campaignState,
    (campaign) => campaign.filterRange
);
export const selectFilterFromDate = createSelector(
    campaignState,
    (campaign) => campaign.filterfromDate
);
export const selectFilterToDate = createSelector(
    campaignState,
    (campaign) => campaign.filterToDate
);


export const { setFilterStatus, setFilterType, setFilterRange,setFilterFromDate, setFilterToDate } = campaignSlice.actions;


export default campaignSlice.reducer;
