import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { fetchCampaigns, selectFilterStatus, selectFilterType, selectFilterRange, setFilterToDate, setFilterFromDate} from 'redux/features/campaignSlice/campaignSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import dayjs from 'dayjs'; 

const SelectDatePersonalized = () => {
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const currentFilterStatus = useSelector(selectFilterStatus);
  const currentFilterType = useSelector(selectFilterType);
  const currentFilterRange =useSelector(selectFilterRange);
  const dispatch = useAppDispatch()

  const handleFilterClick = (fromDate: number, toDate: number) => {
    dispatch(setFilterFromDate(fromDate));
    dispatch(setFilterToDate(toDate));
    dispatch(fetchCampaigns({ status:currentFilterStatus , type: currentFilterType, range: currentFilterRange, fromDate, toDate }));
  };

  const handleFromChange = (newValue) => {
    if (newValue) {
      const startOfDay = newValue.startOf('day').unix(); 
      setFromDate(startOfDay);
    }
  };

  const handleToChange = (newValue) => {
    if (newValue) {
      const endOfDay = newValue.endOf('day').unix(); 
      setToDate(endOfDay);
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label="Desde"
            format="DD-MM-YYYY"
            onChange={handleFromChange} 
          />
        </DemoContainer>
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label="Hasta"
            format="DD-MM-YYYY"
            onChange={handleToChange} 
          />
        </DemoContainer>
      </LocalizationProvider>
      <Button
        
        sx={{
          mb: "10px",
        }}
         onClick={() => handleFilterClick(Number(fromDate),Number(toDate))}
      >
        Filtrar
      </Button>
    </>
  );
}

export default SelectDatePersonalized;

