import {
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  Checkbox,
  Box as MuiBox,
  Typography,
  styled,
  CircularProgress,
} from '@mui/material';
// Types
import { IList, ISegment } from '@trii/types/dist/Contacts';
import { useEffect } from 'react';

const HeaderBox = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 1,
});

const StyledListItem = styled(MuiListItem)({
  cursor: 'pointer',
});

const StyledListItemText = styled(MuiListItemText)(({ theme }) => ({
  userSelect: 'none',
  color: theme.palette.text.primary
}));

type RecipientListProps = {
  data: IList[] | ISegment[];
  handleToggle: (id: string) => void;
  checkedItems: string[];
  loading: boolean;
  onUnmount: () => void;
};

const RecipientList = ({
  data,
  handleToggle,
  checkedItems,
  loading,
  onUnmount,
}: RecipientListProps) => {
  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <MuiBox sx={{ paddingY: 2 }}>
      {/* Header */}
      <HeaderBox>
        <Typography variant="subtitle1" sx={{color: 'text.primary'}}>Disponible</Typography>
        <Typography variant="body2" sx={{color: 'text.primary'}}>
          {`${checkedItems.length}/${data?.length} Seleccionados`}
        </Typography>
      </HeaderBox>

      {/* List of items */}
      <List>
        {data?.map((item) => (
          <StyledListItem
            key={item.id}
            disablePadding
            onClick={() => handleToggle(item.id)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checkedItems.includes(item.id)}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.id}` }}
              />
            </ListItemIcon>
            <StyledListItemText
              id={`checkbox-list-label-${item.id}`}
              primary={item.name}
            />
          </StyledListItem>
        ))}
      </List>
    </MuiBox>
  );
};

export default RecipientList;
