import React from 'react';
// import './CampaignStatusTag.css';


const statusLabels = {
    0: 'Deleted',
    1: 'Design',
    2: 'Scheduled',
    3: 'Sending',
    4: 'Completed',
    5: 'Canceled',
    20: 'Processing',
};

const statusStyles = {
    0: 'bg-red-200 text-red-800',
    1: 'bg-green-200 text-green-800',
    2: 'bg-blue-200 text-blue-800',
    3: 'bg-yellow-200 text-yellow-800',
    4: 'bg-indigo-200 text-indigo-800',
    5: 'bg-gray-200 text-gray-800',
    20: 'bg-purple-200 text-purple-800',
};

const CampaignStatusTag = ({ status }) => {
    const label = statusLabels[status] || 'Unknown';
    const styleClass = statusStyles[status] || 'bg-gray-200 text-gray-800';

    return <span className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${styleClass}`}>{label}</span>;
};

export default CampaignStatusTag;
