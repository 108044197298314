import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Text } from './components';
import { TemplatesContext } from 'context/Templates/TemplatesContext';
import { style } from './style';
import { TemplatesList } from 'context/Templates/types/Lists';
// Types

interface MessageProps {
  selectedTemplate: TemplatesList | null;
  focusType: 'templateList' | 'preview' | null;
}

const Message: React.FC<MessageProps> = ({ selectedTemplate, focusType }) => {
  return (
    <Box sx={style.container}>
      <Box
        sx={{
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.messageBox,
          ...style.secondaryContainer,
        }}
      >
        <Box sx={style.titleContainer}>
          <Box display="flex" gap={0.5} alignItems="center" mb={0.5}>
            <WhatsApp sx={style.whatsappIcon} />
            <Box sx={{ width: '100%' }}>{selectedTemplate?.title || ''}</Box>
          </Box>
        </Box>
        <Text focusType={focusType} selectedTemplate={selectedTemplate} />
      </Box>
    </Box>
  );
};

export default Message;
