import React, { useEffect, useState } from 'react';
import {Box, Button} from '@mui/material';
import { useReport } from 'features/Views/Reports/context/ReportContext';
import { LoadingAppliedFilters } from '../SidebarFilter/components';

const ReportViewer = () => {
  const [pdfUrl, setPdfUrl] = useState('');

  const {toggleReport, selectedCampaignId} = useReport();

  const spaceInfo =JSON.parse(localStorage.getItem('spaceInfo'));
  const spaceId = spaceInfo.id

  const getTokenFromCookies = () => {
    const cookies = document.cookie.split('; ');
    const tokenCookie = cookies.find(cookie => cookie.includes('idToken'));
    if (tokenCookie) {
      return tokenCookie.split('=')[1]; 
    }
    return null;
  };

  useEffect(() => {
    const token = getTokenFromCookies();
    const fetchPdf = async () => {
      try {
        const response = await fetch(`https://agent-api.trii.app/api/v1/reports/${spaceId}/JsReport`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/pdf',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            templateName: "summary",
            moduleName: "marketing",
            filter: {
              campaignId: selectedCampaignId
            }
          }),
        });
        
        const pdfBlob = await response.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
      } catch (error) {
        console.error('Error al obtener el PDF:', error);
      }
    };

    fetchPdf();
  }, []);

  return (
    <Box
    sx={{
      flexGrow: 1,
      height: '100%',
      width: '85%',
      overflow: 'auto',
      borderRadius: '2px',
      boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
      display:'flex', 
    }}>
      {pdfUrl ? (
          <><iframe
          src={pdfUrl}
          height='1050px'
          width='800px'
          title="PDF Viewer"
          style={{
            border: 'none',
            marginTop: '20px',
            marginLeft: '100px',
            marginRight: '30px'
          }} />
          <Button  variant="contained" onClick={toggleReport} style={{height:'40px', width:'90px' ,marginTop:'50px'}}>Volver</Button></>

      ) : (
        <Box
        sx={{
          height: '100%',
          width: '100%',
          display:'flex',
          justifyContent: 'center', alignItems: 'center',}}
        >
          <LoadingAppliedFilters/>
        </Box>
      )}
    </Box>
  );
};

export default ReportViewer;
