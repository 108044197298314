import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
// Types
import { CampaignContactsOrigin } from '@trii/types/dist/Marketing';
//Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setRecipientsContactsOrigin } from 'redux/features/createCampaignSlice/createCampaignSlice';

interface OriginsProps {
  contactsOrigin: CampaignContactsOrigin;
}

const Origins = ({ contactsOrigin }: OriginsProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  return (
    <Box display="flex" alignItems="center" sx={{color: 'text.primary'}}>
      <FormLabel component="legend" sx={{ marginRight: 2 }}>
        {t('global.origin')}
      </FormLabel>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={contactsOrigin}
        onChange={(e) =>
          dispatch(setRecipientsContactsOrigin(Number(e.target.value)))
        }
      >
        <FormControlLabel
          value={CampaignContactsOrigin.LIST}
          control={<Radio />}
          label={t('campaigns.contactList')}
        />
        <FormControlLabel
          value={CampaignContactsOrigin.SEGMENT}
          control={<Radio />}
          label={t('campaigns.segments')}
        />
        <FormControlLabel
          value={CampaignContactsOrigin.XLSX}
          control={<Radio />}
          label="Excel"
        />
      </RadioGroup>
    </Box>
  );
};

export default Origins;
