import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';

interface SelectDropdownProps {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({ value, onChange }) => {
  return (
    <FormControl size="small" sx={{ width: 250}}>
      <InputLabel id="select-label">Canal</InputLabel>
      <Select labelId="select-label" value={value} onChange={onChange} label="Canal"  sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="cloudAPI_3584647642">cloudAPI_3584647642</MenuItem>
        <MenuItem value="API_2">API_2</MenuItem>
        <MenuItem value="API_3">API_3</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;
