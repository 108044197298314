import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Hooks
import { useStepHandler } from 'hooks/useStepHandler';
// Components/ui
import { Stepper, Step, StepLabel, Box, Button, styled, useTheme, Typography } from '@mui/material';
import {
  CampaignTypeStep,
  CampaignNameStep,
  RecipientsStep,
  WritingStep,
} from './components';
// Redux
import { useSelector } from 'react-redux';
import {
  selectCampaignName,
  selectRecipients,
  selectSelectedListIds,
  selectSelectedSegmentIds,
} from 'redux/features/createCampaignSlice/selectors';
import { CampaignContactsOrigin } from '@trii/types/dist/Marketing';
import { resetTemplatesState } from 'redux/features/templatesSlice/templatesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const steps: string[] = ['campaignType', 'campaignName', 'recipients', 'drafting'];


const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  //@ts-ignore
  backgroundColor: theme.palette.background.dropdownMenu,
  position: 'relative',
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  width: '100%', // Full width
  padding: '20px',
  //@ts-ignore
  backgroundColor: theme.palette.background.dropdownMenu,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const BodySection = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%'
}));

const StepperSection = styled(Box)(({ theme }) => ({
  padding: '20px',
  //@ts-ignore
  backgroundColor: theme.palette.background.dropdownMenu,
}));

const ContentSection = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '40px',
  //@ts-ignore
  backgroundColor: theme.palette.background.dropdownMenu,
  margin: '20px',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const MainStepper: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activeStep, handleNext, handleBack, isLastStep } = useStepHandler();

  const campaignName = useSelector(selectCampaignName);
  const { contactsOrigin } = useSelector(selectRecipients);
  const selectedListsIds = useSelector(selectSelectedListIds);
  const selectedSegmentsIds = useSelector(selectSelectedSegmentIds);
  const getTitleByStep = (step: number): string => {
    switch (step) {
      case 0:
        return "campaigns.newCampaign";
      case 1:
        return "campaigns.newCampaign";
      case 2:
        return campaignName;
      case 3:
        return campaignName;
      default:
        return "campaigns.newCampaign";
    }
  };
  
  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return <CampaignTypeStep handleNext={handleNext} />;
      case 1:
        return <CampaignNameStep />;
      case 2:
        return <RecipientsStep />;
      case 3:
        return <WritingStep />;
      default:
        return <div>Unknown step</div>;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetTemplatesState());
    };
  }, []);

  return (
    <MainContainer>
      {/* Header Section */}
      <HeaderSection>
        <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
          {t(getTitleByStep(activeStep))}
        </Typography>
        <ButtonContainer>
          <Button
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            {t("global.back")}
          </Button>
          <Button
            disabled={
              activeStep === 0 ||
              (activeStep === 1 && campaignName === '') ||
              (activeStep === 2 && contactsOrigin === CampaignContactsOrigin.LIST && selectedListsIds.length === 0) ||
              (activeStep === 2 && contactsOrigin === CampaignContactsOrigin.SEGMENT && selectedSegmentsIds.length === 0)
            }
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {t(`global.${isLastStep ? "finalize" : "next"}`)}
          </Button>
        </ButtonContainer>
      </HeaderSection>
      <BodySection>

      <StepperSection>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    color: activeStep === index ? 'primary.main' : 'text.secondary',
                  },
                }}
                sx={{
                  '& .MuiStepLabel-label': {
                    fontWeight: activeStep === index ? 'bold' : 'normal',
                    fontSize: activeStep === index ? '1.1rem' : '1rem',
                    color: activeStep === index ? 'primary.main' : 'text.secondary',
                  },
                }}
              >
                <Typography variant="body1">{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperSection>
      
      <ContentSection>
        {getStepContent(activeStep)}
      </ContentSection>
      </BodySection>
    </MainContainer>
  );
};

export default MainStepper;
