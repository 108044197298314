import { NodeMsgTextMessageType } from '@trii/types/dist/Conversations/Flows/Nodes';

type Format = {
  id: number;
  name: string;
};

const formatList: Format[] = [
  {
    id: NodeMsgTextMessageType.TEXT,
    name: 'global.text',
  },
  {
    id: NodeMsgTextMessageType.IMAGE,
    name: 'global.image',
  },
  {
    id: NodeMsgTextMessageType.VIDEO,
    name: 'global.video',
  },
  {
    id: NodeMsgTextMessageType.FILE,
    name: 'global.file',
  },
];

export default formatList;
