import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
import { ICampaign } from '@trii/types/dist/Marketing';

const fetchCampaigns = async (
    jwtToken: string,
    URL: string,
    status: string,
    type: number | null,
    range: number | null,
    fromDate: number | null,
    toDate: number | null
): Promise<ICampaign[]> => {
    const config = getRequestConfig.basic(jwtToken);

    console.log('Status enviado:', status);

    let requestUrl = `${URL}/Campaigns?status=${status}`;
    if (type !== null) {
        requestUrl += `&type=${type}`;
    }
    if (range !== null) {
        requestUrl += `&timeRange=${range}`;
    }
    if (fromDate !== null && toDate !== null) {
        requestUrl += `&fromDate=${fromDate}`;
        requestUrl += `&toDate=${toDate}`;
    }
    console.log(requestUrl)
    const response = await axios.get<ICampaign[]>(requestUrl, config);  

    return response.data;
    
};

const fetchPauseCampaign = async (jwt, URL, campaignId) => {
    const requestConfig = getRequestConfig.basic(jwt);
    const response = await axios.put(
        `${URL}/Campaigns/${campaignId}/status?action=pause`,
        null,
        requestConfig
    );

    return response.data;
}

const fetchResumeCampaign = async (jwt, URL, campaignId) => {
    const requestConfig = getRequestConfig.basic(jwt);
    const response = await axios.put(
        `${URL}/Campaigns/${campaignId}/status?action=resume`,
        null,
        requestConfig
    );

    return response.data;
}

const cancelCampaignSend = async (jwt: string, URL: string, campaignId: string) => {
    const requestConfig = getRequestConfig.basic(jwt);
    const response = await axios.put(
        `${URL}/Campaigns/${campaignId}/status?status=5`,
        null,
        requestConfig
    );
    return response.data;
};

const deleteCampaign = async (jwt: string, URL: string, campaignId: string) => {
    const requestConfig = getRequestConfig.basic(jwt);
    const response = await axios.put(
        `${URL}/Campaigns/${campaignId}/status?status=20`,
        null,
        requestConfig
    );
    return response.data;
};

const cloneCampaign = async (jwt: string, URL: string, campaignId: string) => {
    const requestConfig = getRequestConfig.basic(jwt);
    const response = await axios.post(
        `${URL}/Campaigns/${campaignId}/clone`,
        null,
        requestConfig
    );
    return response.data;
};



const campaignSliceService = {
    fetchCampaigns,
    fetchPauseCampaign,
    fetchResumeCampaign,
    cancelCampaignSend,
    deleteCampaign,
    cloneCampaign
};

export default campaignSliceService;