import { createContext, useState } from 'react';
// Utils
import { fieldInitialDef } from 'utils/fieldInitialDef';
// Hooks
import useField from 'hooks/useField';
//Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import {
  createSchedule,
  deleteSchedule,
  fetchScheduleByName,
} from 'redux/features/scheduleSlice/scheduleSlice';
// Types
import { ScheduleContext, ScheduleProviderProps } from './types';
import { UseFieldType } from 'hooks/useField';
import { ISchedule } from '@trii/types/dist/Common/Schedules';
import { useNavigate } from 'react-router-dom';

export const InitScheduleContext = createContext<ScheduleContext>({
  deletePanelOpen: false,
  createPanelOpen: false,
  createScheduleNameField: fieldInitialDef,
  createScheduleDescriptionField: fieldInitialDef,
  deleteScheduleNameField: fieldInitialDef,
  showAddSchedule: false,
  setShowAddSchedule: () => { },
  setDeletePanelOpen: () => { },
  setCreatePanelOpen: () => { },
  openCreatePanel: () => { },
  closeCreatePanel: () => { },
  resetCreateFields: () => { },
  sendSchedule: () => { },
  openDeletePanel: () => { },
  closeDeletePanel: () => { },
  sendDeleteSchedule: () => { },
  navigateToEdit: () => { },
});

export const ScheduleProvider = ({ children }: ScheduleProviderProps) => {
  const navigate = useNavigate();

  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
  // Create state
  const [createPanelOpen, setCreatePanelOpen] = useState<boolean>(false);
  const [showAddSchedule, setShowAddSchedule] = useState<boolean>(false);
  const createScheduleNameField: UseFieldType = useField('text');
  const createScheduleDescriptionField: UseFieldType = useField('text');
  // Delete state
  const [deletePanelOpen, setDeletePanelOpen] = useState<boolean>(false);
  const deleteScheduleNameField: UseFieldType = useField('text');

  // Create functions
  const openCreatePanel = () => {
    closeDeletePanel();
    setCreatePanelOpen(true);
  };
  const closeCreatePanel = () => {
    resetCreateFields();
    setCreatePanelOpen(false);
  };
  const resetCreateFields = () => {
    createScheduleNameField.actions.resetValue();
    createScheduleDescriptionField.actions.resetValue();
  };
  const sendSchedule = async () => {
    await dispatch(
      createSchedule({
        id: null,
        spaceId: null,
        name: createScheduleNameField.attributes.value,
        description: createScheduleDescriptionField.attributes.value,
        holidays: null,
        timeRanges: [],
      })
    );
    closeCreatePanel();
  };
  // Delete functions
  const openDeletePanel = (scheduleId: string) => {
    dispatch(fetchScheduleByName(scheduleId));
    setDeletePanelOpen(true);
  };
  const closeDeletePanel = () => {
    deleteScheduleNameField.actions.resetValue();
    setDeletePanelOpen(false);
  };
  const sendDeleteSchedule = async (scheduleId: string) => {
    await dispatch(deleteSchedule(scheduleId));
    closeDeletePanel();
  };

  const navigateToEdit = (scheduleId: string) => {
    navigate(`/a/conversations/system/schedule/${scheduleId}`);
  };

  return (
    <InitScheduleContext.Provider
      value={{
        sendDeleteSchedule,
        closeDeletePanel,
        deleteScheduleNameField,
        openDeletePanel,
        deletePanelOpen,
        setDeletePanelOpen,
        createScheduleNameField,
        createScheduleDescriptionField,
        resetCreateFields,
        sendSchedule,
        createPanelOpen,
        setCreatePanelOpen,
        openCreatePanel,
        closeCreatePanel,
        showAddSchedule,
        setShowAddSchedule,
        navigateToEdit,
      }}
    >
      {children}
    </InitScheduleContext.Provider>
  );
};

export default ScheduleProvider;
