import { useState, useEffect } from 'react';
import { useContext } from 'react';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useDispatch ,useSelector,} from 'react-redux';
// import {
//   selectContacts,
//   selectBusiness,
//   selectContactsFetchStatus,
//   getFetchContactFieldsStatus,
//   selectBusinessFetchStatus,
// } from 'redux/features/contactsSlice/contactsSlice';
// Components/ui
import { TableBody } from '@mui/material';
import {CircularProgress} from '@mui/material';
import { Contact } from './components';

import {fetchCampaigns, selectCampaigns, selectCampaignStatus, selectFilterRange, selectFilterStatus, selectFilterType, selectFilterFromDate, selectFilterToDate } from 'redux/features/campaignSlice/campaignSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

import campaignSliceService from 'redux/features/campaignSlice/campaignSliceService'
import { ICampaign } from "@trii/types/dist/Campaigns";

import { LoadingAppliedFilters } from '../../../../../SidebarFilter/components';

const Body = () => {
  // const { mode } = useContext(ContactBusinessTableContext);
  // const columnsFetchStatus = useSelector(getFetchContactFieldsStatus);
  // const itemsFetchStatus = useSelector(
  //   mode === 'contact' ? selectContactsFetchStatus : selectBusinessFetchStatus
  // );

  // const items =
  //   mode === 'contact' ? useSelector(selectContacts) : useSelector(selectBusiness);

  const dispatch = useAppDispatch();  // Obtenemos la función dispatch de Redux
  const campaigns = useSelector(selectCampaigns);  // Seleccionamos las campañas del estado global
  const status = useSelector(selectCampaignStatus);  // Seleccionamos el estado (loading, succeeded, etc.)
  const filterStatus = useSelector(selectFilterStatus);
  const filterType = useSelector(selectFilterType);
  const filterRange =useSelector(selectFilterRange);
  const filterFromDate =useSelector(selectFilterFromDate);
  const filterToDate =useSelector(selectFilterToDate);

  useEffect(() => {
      if (status.campaigns === 'idle') {
        
        dispatch(fetchCampaigns({status: filterStatus, type: filterType, range:filterRange, fromDate: filterFromDate, toDate: filterToDate}));  
      }
      
    }, [dispatch, status.campaigns, filterStatus, filterType, filterRange,filterFromDate, filterToDate]);  

  if (status.campaigns === 'loading') {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',   // Ocupará el 80% del alto de la pantalla
        width: '80vw',   // Ocupará todo el ancho de la pantalla
      }}>
        <CircularProgress size={100} />  {/* Ajusta el tamaño del spinner aquí */}
      </div>
    );
  }

  if (status.campaigns === 'rejected') {
    return <div>Error al cargar las campañas.</div>;  
  }

  // const Items = <Contact />;

  return (
    <TableBody>
      {campaigns.map((campaign) => (
        <Contact key={campaign.id} campaign={campaign} /> 
      ))}
    </TableBody>
  );
};
export default Body;
