import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { InitialState, EditScheduleData } from './types';
import { ISchedule } from '@trii/types/dist/Common/Schedules';

const fetchSchedulesService = async (
    jwtToken: string,
    URL_MARKETING: string
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
        },
    };

    const { data } = await axios.get(`${URL_MARKETING}/schedules`, config);
    return data;
};

const fetchScheduleByNameService = async (
    jwtToken: string,
    URL_MARKETING: String,
    name: string
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
        },
    };

    const { data } = await axios.get(`${URL_MARKETING}/schedules/${name}`, config);
    return data;
};

const createScheduleService = async (
    jwtToken: string,
    URL_MARKETING: string,
    newSchedule: ISchedule
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        },
    };

    const newScheduleJSON = JSON.stringify(newSchedule);
    const { data } = await axios.post(`${URL_MARKETING}/schedules`, newScheduleJSON, config);
    return data;
};

const deleteScheduleService = async (
    jwtToken: string,
    URL_MARKETING: string,
    scheduleId: string
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
        },
    };

    await axios.delete(`${URL_MARKETING}/schedules/${scheduleId}`, config);

    return scheduleId;
};

const editScheduleService = async (
    jwtToken: string,
    URL_MARKETING: string,
    scheduleId: string,
    editedSchedule: ISchedule
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        },
    };

    const editedScheduleJson = JSON.stringify(editedSchedule);
    await axios.put(`${URL_MARKETING}/schedules/${scheduleId}`, editedScheduleJson, config);

    return editedSchedule;
};

const scheduleSliceService = {
    fetchScheduleByNameService,
    fetchSchedulesService,
    createScheduleService,
    deleteScheduleService,
    editScheduleService,
};

export default scheduleSliceService;