import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PerformanceSliceState } from './types/dashboardSliceState';
import { 
    ActivityOverTimeDashboard, 
    DeliveriesDashboard, 
    PerformanceDashboard, 
    RecipientsDashboard, 
    ReportingPanelDashboard 
} from '@trii/types/dist/Marketing';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import dashboardSliceService from './dashboardSliceService';
import { initRequestData } from 'redux/functions/initRequestData';

const initialState: PerformanceSliceState = {
    performance: null,
    reportingPanel: null,             
    recipients: null,        
    deliveries: null,         
    activityOverTime:null,
    status: {
        performance: 'idle',
        reportingPanel: 'idle',             
        recipients: 'idle',          
        deliveries: 'idle',         
        activityOverTime:'idle',
    },
};

export const fetchPerformanceData = createAsyncThunk(
    'dashboard/fetchPerformanceData',
    async (_, { dispatch }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData()))
            .payload as InitRequestDataReturn;

        try {
            const response = await dashboardSliceService.fetchPerformanceData(jwtToken, URL_MARKETING);
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

export const fetchReportingPanelData = createAsyncThunk(
    'dashboard/fetchReportingPanelData',
    async (_, { dispatch }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData()))
            .payload as InitRequestDataReturn;

        try {
            const response = await dashboardSliceService.fetchReportingPanelData(jwtToken, URL_MARKETING);
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

export const fetchRecipientsData = createAsyncThunk(
    'dashboard/fetchRecipientsData',
    async (_, { dispatch }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData()))
            .payload as InitRequestDataReturn;

        try {
            const response = await dashboardSliceService.fetchRecipientsData(jwtToken, URL_MARKETING);
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

export const fetchDeliveriesData = createAsyncThunk(
    'dashboard/fetchDeliveriesData',
    async (_, { dispatch }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData()))
            .payload as InitRequestDataReturn;

        try {
            const response = await dashboardSliceService.fetchDeliveriesData(jwtToken, URL_MARKETING);
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);

export const fetchActivityOverTimeData = createAsyncThunk(
    'dashboard/fetchActivityOverTimeData',
    async (_, { dispatch }) => {
        const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData()))
            .payload as InitRequestDataReturn;

        try {
            const response = await dashboardSliceService.fetchActivityOverTimeData(jwtToken, URL_MARKETING);
            return response;
        } catch (error) {
            throw new Error(error.response.data.message);
        }
    }
);


export const dashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {
        resetDashboardState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPerformanceData.pending, (state) => {
                state.status.performance = 'loading';
            })
            .addCase(
                fetchPerformanceData.fulfilled,
                (state, action: PayloadAction<PerformanceDashboard>) => {
                    state.status.performance = 'succeeded';
                    state.performance = action.payload;
                }
            )
            .addCase(fetchPerformanceData.rejected, (state) => {
                state.status.performance = 'rejected';
            })

            // Reporting Panel

            .addCase(fetchReportingPanelData.pending, (state) => {
                state.status.reportingPanel = 'loading';
            })
            .addCase(
                fetchReportingPanelData.fulfilled,
                (state, action: PayloadAction<ReportingPanelDashboard>) => {
                    state.status.reportingPanel = 'succeeded';
                    state.reportingPanel = action.payload;
                }
            )
            .addCase(fetchReportingPanelData.rejected, (state) => {
                state.status.reportingPanel = 'rejected';
            })

            // Recipients

            .addCase(fetchRecipientsData.pending, (state) => {
                state.status.recipients = 'loading';
            })
            .addCase(fetchRecipientsData.fulfilled, (state, action: PayloadAction<RecipientsDashboard[]>) => {
                state.status.recipients = 'succeeded';
                state.recipients = action.payload;
            })
            .addCase(fetchRecipientsData.rejected, (state) => {
                state.status.recipients = 'rejected';
            })

            // Deliveries
            .addCase(fetchDeliveriesData.pending, (state) => {
                state.status.deliveries = 'loading';
            })
            .addCase(fetchDeliveriesData.fulfilled, (state, action: PayloadAction<DeliveriesDashboard>) => {
                state.status.deliveries = 'succeeded';
                state.deliveries = action.payload;
            })
            .addCase(fetchDeliveriesData.rejected, (state) => {
                state.status.deliveries = 'rejected';
            })
            // Activity Over Time
            .addCase(fetchActivityOverTimeData.pending, (state) => {
                state.status.activityOverTime = 'loading';
            })
            .addCase(fetchActivityOverTimeData.fulfilled, (state, action: PayloadAction<ActivityOverTimeDashboard>) => {
                state.status.activityOverTime = 'succeeded';
                state.activityOverTime = action.payload;
            })
            .addCase(fetchActivityOverTimeData.rejected, (state) => {
                state.status.activityOverTime = 'rejected';
            });
    },
});

export const { resetDashboardState } = dashboardSlice.actions;
export default dashboardSlice.reducer;
