import React from 'react';

import { Card, Title, BarChart } from "@tremor/react";
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const GraphicFinalsCard = ( {data} ) => {
  const theme = useTheme();
  const {t} = useTranslation();
 
  const chartData = data.map(item => ({
    name: item.name || t("dashboard.noName"), // Asegúrate de tener también una traducción para "Sin nombre"
    [t("dashboard.diffusionBehavior")]: item.quantity || 0 
  }));

  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      }}
    >
      <Title style={{ color: theme.palette.text.primary }}>{t("dashboard.finals")}</Title>
      <Box
        sx={{
          width: "100%",
          "& div div div div .bg-white": {
            //@ts-ignore
            backgroundColor: theme.palette.background.panel,
            color: theme.palette.text.primary
          },
        }}
      >
        <BarChart
          className="mt-6"
          data={chartData}
          index="name"
          categories={[t("dashboard.diffusionBehavior")]}
          colors={["blue"]}
          yAxisWidth={48}
          style={{ color: theme.palette.text.primary }}
        />
      </Box>
    </Card>
  );
};

export default GraphicFinalsCard;
