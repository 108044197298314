import axios from 'axios';
// Utils
import getRequestConfig from 'redux/functions/getRequestConfig';
// Types
import { CreateCampaignData, Recipients } from './types';
import { ICampaign } from '@trii/types/dist/Marketing';

async function createCampaign(
  URL: string,
  jwtToken: string,
  data: CreateCampaignData
) {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  try {
    const response = await axios.post<ICampaign>(`${URL}/Campaigns`, data, config);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

async function updateCampaignRecipients(
  URL: string,
  jwtToken: string,
  data: Recipients,
  campaignId: string
) {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  try {
    const response = await axios.put<ICampaign>(
      `${URL}/Campaigns/${campaignId}`,
      data,
      config
    );

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export default {
  createCampaign,
  updateCampaignRecipients,
};
