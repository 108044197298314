import { useContext, useEffect } from "react";
import { Box, CircularProgress,Typography } from "@mui/material";
import {
  GraphicFinalsCard,
  GroupUserCard,
  MembersCard,
  NumberCard,
  Period,
  RegisterModal,
  SimpleListCard,
  TimesCard,
} from "./components";
import { useTranslation } from "react-i18next";
import {
  DashboardContext,
  DashboardProvider,
} from "./context/DashboardContext";
import { useSelector } from 'react-redux';
import { useAppDispatch } from "hooks/useAppDispatch";
import { RootState } from "redux/features/store";
import { 
  fetchPerformanceData, 
  fetchReportingPanelData,
  fetchRecipientsData,
  fetchDeliveriesData,
  fetchActivityOverTimeData
} from "redux/features/dashboardSlice/dashboardSlice";
import { DataContext } from "context/contextApi";
import { Flex } from "@tremor/react";
import GrapichDestiny from "./components/GraphicDestiny/GrapichDestiny";
import PerformanceCard from "./components/PerformanceCard";
// import { getSpaces } from "redux/features/spacesSlice/spacesSlice";
// import { useAppDispatch } from "hooks/useAppDispatch";

const Dashboard = () => {
  const { t } = useTranslation();
  const { isMobile } = useContext(DataContext);

  const dispatch = useAppDispatch();
  

  const performanceStatus = useSelector((state: RootState) => state.dashboard.status.performance);
  const reportingPanelStatus = useSelector((state: RootState) => state.dashboard.status.reportingPanel);
  const recipientsStatus = useSelector((state: RootState) => state.dashboard.status.recipients);
  const deliveriesStatus = useSelector((state: RootState) => state.dashboard.status.deliveries);
  const activityOverTimeStatus = useSelector((state: RootState) => state.dashboard.status.activityOverTime);
  
  const performance = useSelector((state: RootState) => state.dashboard.performance);
  const reportingPanel = useSelector((state: RootState) => state.dashboard.reportingPanel);
  const recipients = useSelector((state: RootState) => state.dashboard.recipients);
  const deliveries = useSelector((state: RootState) => state.dashboard.deliveries);
  const activityOverTime = useSelector((state: RootState) => state.dashboard.activityOverTime);

  useEffect(() => {
    if (performanceStatus === 'idle') {
      dispatch(fetchPerformanceData());
    }
    if (reportingPanelStatus === 'idle') {
      dispatch(fetchReportingPanelData());
    }
    if (recipientsStatus === 'idle') {
      dispatch(fetchRecipientsData());
    }
    if (deliveriesStatus === 'idle') {
      dispatch(fetchDeliveriesData());
    }
    if (activityOverTimeStatus === 'idle') {
      dispatch(fetchActivityOverTimeData());
    }
  }, [dispatch, performanceStatus, reportingPanelStatus, recipientsStatus, deliveriesStatus, activityOverTimeStatus]);

  return (
    <DashboardProvider>
      <Box p={2} sx={!isMobile ? { minWidth: "1024px" } : undefined}>
      <Box  >
        {/* cards */}
        <Box className="card-container">
          {/* Rendimiento */}
          <Box
            style={{
              display: "flex",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {t("dashboard.performance")}
            </Typography>
          </Box>
          <Box
            className="marketing-row"
            sx={{ justifyContent: "space-between", pt: "10px" }}
          >
            <Flex
              className="overflow-x-auto pb-1.5"
              style={
                {
                  // maxWidth: "calc(100vw - 165px)",
                  // paddingRight: "5px",
                  // paddingLeft: "5px",
                }
              }
            >
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="channels"
                  title={t("dashboard.openingRate")}
                  number={performance?.openingRate.total || 0}
                  list={performance?.openingRate.details  || []}
                  isLoading={performanceStatus === 'loading'}

                />
              </Box>
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="tagList"
                  title={t("dashboard.clicksRate")}
                  number={performance?.clickRate.total || 0}
                  list={performance?.clickRate.details || []}
                  isLoading={performanceStatus === 'loading'}
                />
              </Box>
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="tagList"
                  title={t("dashboard.shared")}
                  number={performance?.shared.total || 0}
                  list={performance?.shared.details || []}
                  isLoading={performanceStatus === 'loading'}
                />
              </Box>
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="tagList"
                  title={t("dashboard.unsubscriptions")}
                  number={performance?.unsubscriptions.total || 0}
                  list={performance?.unsubscriptions.details || []}
                  isLoading={performanceStatus === 'loading'}

                />
              </Box>
              <Box className="marketing-col-2" pt={"10px"}>
                <PerformanceCard
                  id="tagList"
                  title={t("dashboard.complaints")}
                  number={performance?.complaints.total || 0 }
                  list={performance?.complaints.details || []}
                  isLoading={performanceStatus === 'loading'}
                />
              </Box>
            </Flex>
          </Box>
          {/* Panel de reportes */}
          <Box
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {t("dashboard.reportPanel")}
            </Typography>
          </Box>
          <Box
            className="marketing-row"
            sx={{ justifyContent: "center", pt: "10px" }}
          >
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.sentCampaigns")}
                number={reportingPanel?.totalCampaigns}
                type="dashboard.cardConversations"  
                isLoading={reportingPanelStatus === 'loading'}
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.shipmentsMade")}
                number={reportingPanel?.shipments}
                type="dashboard.cardNewsInline"
                isLoading={reportingPanelStatus === 'loading'}
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalOpenings")}
                number={reportingPanel?.totalOpenings}
                type="dashboard.cardInProgressInAgents"
                isLoading={reportingPanelStatus === 'loading'}
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.expired")}
                number={reportingPanel?.expired}
                type="dashboard.cardExpired"
                isLoading={reportingPanelStatus === 'loading'}
              />
            </Box>
          </Box>

          <Box
            className="marketing-row"
            sx={{ justifyContent: "center", pt: "10px" }}
          >
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalShares")}
                number={reportingPanel?.totalShared}
                type="dashboard.cardConversations"
                isLoading={reportingPanelStatus === 'loading'}
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalUsubscriptions")}
                number={reportingPanel?.totalUnsubscriptions}
                type="dashboard.cardConversations"
                isLoading={reportingPanelStatus === 'loading'}
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalComplaints")}
                number={reportingPanel?.totalComplaints}
                type="dashboard.cardConversations"
                isLoading={reportingPanelStatus === 'loading'}
              />
            </Box>
            <Box className="marketing-col-3">
              <NumberCard
                title={t("dashboard.totalRebounds")}
                number={reportingPanel?.totalBounces}
                type="dashboard.cardConversations"
                isLoading={reportingPanelStatus === 'loading'}
              />
            </Box>
          </Box>
          {/* Destinatarios y entregas */}
          <Box
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {t("dashboard.recipientsAndDeliveries")}
            </Typography>
          </Box>
          <Box
            className="marketing-row"
            sx={{ justifyContent: "center", pt: "10px" }}
          >
            <Box className="marketing-col-6">
              <SimpleListCard 
              id="channels" 
              title={t("dashboard.recipients")} 
              list={recipients || []}
              isLoading={recipientsStatus === 'loading'}
              />
            </Box>
            <Box className="marketing-col-6">
              <GrapichDestiny 
              data={deliveries || {}} 
              />
            </Box>
          </Box>
          {/* Actividad en el tiempo */}
          <Box
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {t("dashboard.activityOverTime")}
            </Typography>
          </Box>
          <Box
            className="marketing-row"
            sx={{ justifyContent: "center", pt: "10px" }}
          >
            <Box className="marketing-col-12">
              <GraphicFinalsCard 
              data={activityOverTime?.lastThreeMonths || []}
              />
            </Box>
          </Box>
        </Box>
        </Box>
        <RegisterModal></RegisterModal>
      </Box>
    </DashboardProvider>
  );
};

export default Dashboard;
