import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography, styled } from '@mui/material';
import { MainStepper } from './components';
// Redux
import { resetSegmentsSliceState } from 'redux/features/segmentsSlice/segmentsSlice';
import { resetListsSliceState } from 'redux/features/listsSlice/listsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { resetCreateCampaignSliceState } from 'redux/features/createCampaignSlice/createCampaignSlice';

const NewCampaignContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  gap: 16,
  padding: 16,
});

const NewCampaign = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCreateCampaignSliceState());
      dispatch(resetSegmentsSliceState());
      dispatch(resetListsSliceState());
    };
  }, []);

  return (
    <NewCampaignContainer>      
      <MainStepper />
    </NewCampaignContainer>
  );
};

export default NewCampaign;
