// Components/ui
import { Box, Button } from '@mui/material';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setCampaignType } from 'redux/features/createCampaignSlice/createCampaignSlice';
// Types
import { CampaignType } from '@trii/types/dist/Campaigns';

const campaignTypes = ['SMS', 'Email', 'RCS', 'WhatsApp'] as const;

type CampaignTypeStepProps = {
  handleNext: () => void;
};
const CampaignTypeStep = ({ handleNext }: CampaignTypeStepProps) => {
  const dispatch = useAppDispatch();

  const handleCampaignTypeClick = (type: (typeof campaignTypes)[number]) => {
    dispatch(
      setCampaignType(
        type === 'SMS'
          ? CampaignType.SMS
          : type === 'Email'
          ? CampaignType.EMAIL
          : type === 'RCS'
          ? CampaignType.CALL
          : CampaignType.WHATSAPP
      )
    );

    handleNext();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {campaignTypes.map((type) => (
        <Button
          key={type}
          variant="contained"
          onClick={() => handleCampaignTypeClick(type)}
          sx={{ width: '200px' }}
        >
          {type}
        </Button>
      ))}
    </Box>
  );
};

export default CampaignTypeStep;
