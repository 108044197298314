import React, { createContext, useContext, useState } from 'react';

interface ReportContextProps {
  showReport: boolean;
  toggleReport: () => void;
  selectedCampaignId: string | null;
  setselectedCampaignId:  (id: string | null) => void;
}

const ReportContext = createContext<ReportContextProps | undefined>(undefined);

export const ReportProvider = ({ children }) => {
  const [showReport, setShowReport] = useState(false);
  const [selectedCampaignId,setselectedCampaignId ] = useState(null)

  const toggleReport = () => setShowReport(!showReport);

  return (
    <ReportContext.Provider value={{ showReport, toggleReport, selectedCampaignId, setselectedCampaignId }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = () => {
  const context = useContext(ReportContext);
  
  return context;
};
