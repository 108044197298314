// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 8px 8px;
  transition: opacity 0.5s;
  will-change: transform;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailEditor/plugins/FloatingLinkEditorPlugin/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,gBAAgB;EAChB,WAAW;EACX,UAAU;EACV,sBAAsB;EACtB,yCAAyC;EACzC,0BAA0B;EAC1B,wBAAwB;EACxB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;;EAEE,wBAAwB;EACxB,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,uBAAuB;AACzB","sourcesContent":[".link-editor {\n  display: flex;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 10;\n  max-width: 400px;\n  width: 100%;\n  opacity: 0;\n  background-color: #fff;\n  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);\n  border-radius: 0 0 8px 8px;\n  transition: opacity 0.5s;\n  will-change: transform;\n}\n\n.link-editor .button {\n  width: 20px;\n  height: 20px;\n  display: inline-block;\n  padding: 6px;\n  border-radius: 8px;\n  cursor: pointer;\n  margin: 0 2px;\n}\n\n.link-editor .button.hovered {\n  width: 20px;\n  height: 20px;\n  display: inline-block;\n  background-color: #eee;\n}\n\n.link-editor .button i,\n.actions i {\n  background-size: contain;\n  display: inline-block;\n  height: 20px;\n  width: 20px;\n  vertical-align: -0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
