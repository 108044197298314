import { useState, useEffect } from 'react';
// Components/ui
import { CircularProgress, Fade } from '@mui/material';

const LoadingAppliedFilters = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(true);
  }, []);
  return (
    <Fade in={showContent}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    </Fade>
  );
};

export default LoadingAppliedFilters;
