import React, { useEffect } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from 'redux/rootReducer';
import { fetchSchedules } from 'redux/features/scheduleSlice/scheduleSlice';
// Components
import ScheduleProvider from './context/ScheduleContext';
import { Box, Divider } from '@mui/material';
import { ScheduleContent, ScheduleHeader, Panel } from './components/index';


export default function ScheduleView() {
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    useEffect(() => {
        dispatch(fetchSchedules());
    }, [dispatch]);

    return (
        <ScheduleProvider>
            <Box display={'flex'}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    height={'100%'}
                    width={'100%'}
                    className={'conversations-fadeinup conversations-paddingContent'}
                    borderRadius={2}
                    mt={1}
                    position="relative"
                >
                    <ScheduleHeader />
                    <Divider sx={{ marginTop: '10px', marginX: '10px' }} />
                    <ScheduleContent />
                </Box>
                <Panel />
            </Box>
        </ScheduleProvider>
    );
}
