// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/icons/draggable-block-menu.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draggable-block-menu {
  border-radius: 4px;
  padding: 2px 1px;
  cursor: grab;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
}

.draggable-block-menu .icon {
  width: 16px;
  height: 16px;
  opacity: 0.3;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.draggable-block-menu.dark {
  background-color: #bbbbbb;
}

.draggable-block-menu:active {
  cursor: grabbing;
}

.draggable-block-menu:hover {
  background-color: #efefef;
}

.draggable-block-menu.dark:hover {
  background-color: #a0a0a0;
}

.draggable-block-target-line {
  pointer-events: none;
  background: deepskyblue;
  height: 4px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  will-change: transform;
}`, "",{"version":3,"sources":["webpack://./src/components/EmailEditor/plugins/DraggableBlockPlugin/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,yDAAkE;AACpE;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,UAAU;EACV,sBAAsB;AACxB","sourcesContent":[".draggable-block-menu {\n  border-radius: 4px;\n  padding: 2px 1px;\n  cursor: grab;\n  opacity: 0;\n  position: absolute;\n  left: 0;\n  top: 0;\n  will-change: transform;\n}\n\n.draggable-block-menu .icon {\n  width: 16px;\n  height: 16px;\n  opacity: 0.3;\n  background-image: url(../../images/icons/draggable-block-menu.svg);\n}\n\n.draggable-block-menu.dark {\n  background-color: #bbbbbb;\n}\n\n.draggable-block-menu:active {\n  cursor: grabbing;\n}\n\n.draggable-block-menu:hover {\n  background-color: #efefef;\n}\n\n.draggable-block-menu.dark:hover {\n  background-color: #a0a0a0;\n}\n\n.draggable-block-target-line {\n  pointer-events: none;\n  background: deepskyblue;\n  height: 4px;\n  position: absolute;\n  left: 0;\n  top: 0;\n  opacity: 0;\n  will-change: transform;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
