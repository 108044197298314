import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
// Redux
import { useSelector } from 'react-redux';
import {
  selectXlsxFile,
  selectXlsxFileRowValues,
  selectSelectedXlsxFileRowValue,
} from 'redux/features/createCampaignSlice/selectors';
import {
  setXlsxFile,
  setXlsxFileRowValues,
  setSelectedXlsxFileRowValue,
} from 'redux/features/createCampaignSlice/createCampaignSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Utils
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
// Types
import { XlsxRowValue } from 'redux/features/createCampaignSlice/types';

const XlsxOrigin = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Redux selectors
  const selectedFile = useSelector(selectXlsxFile);
  const rowValues = useSelector(selectXlsxFileRowValues);
  const selectedRowValue = useSelector(selectSelectedXlsxFileRowValue);

  // Handle XLSX file upload
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // Clear previous values before loading a new file
      dispatch(setXlsxFileRowValues([]));
      dispatch(setSelectedXlsxFileRowValue(null));
      dispatch(setXlsxFile(file));

      await readFirstRow(file);
    }
  };

  // Read the first row from the uploaded XLSX file
  const readFirstRow = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const data = event.target?.result;
      if (!data) return;

      const workbook = XLSX.read(new Uint8Array(data as ArrayBuffer), {
        type: 'array',
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const row: string[] = jsonData[0] as string[];

      const xlsxRowValues: XlsxRowValue[] = row.map((value: string) => ({
        name: value,
        id: uuidv4(),
      }));

      dispatch(setXlsxFileRowValues(xlsxRowValues));
    };

    reader.readAsArrayBuffer(file);
  };

  // Handle selection change
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    const selectedValue = rowValues.find((value) => value.id === selectedId);
    dispatch(setSelectedXlsxFileRowValue(selectedValue));
  };

  // Cleanup Redux state when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(setXlsxFile(null));
      dispatch(setXlsxFileRowValues([]));
      dispatch(setSelectedXlsxFileRowValue(null));
    };
  }, [dispatch]);

  return (
    <Box sx={{ paddingY: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Button variant="contained" component="label" sx={{ marginRight: 2 }}>
        {t('campaigns.uploadXlsxFile')}
        <input type="file" accept=".xlsx" hidden onChange={handleFileChange} />
      </Button>

      {selectedFile && (
        <Typography variant="body2">
          {t('campaigns.selectedFile')}: {selectedFile.name}
        </Typography>
      )}

      {rowValues.length > 0 && (
        <FormControl fullWidth>
          <InputLabel id="first-row-select-label">
            {t('campaigns.recipientRow')}
          </InputLabel>
          <Select
            labelId="first-row-select-label"
            value={selectedRowValue?.id || ''}
            onChange={handleSelectChange}
            size="small"
          >
            {rowValues.map((value) => (
              <MenuItem key={value.id} value={value.id}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default XlsxOrigin;
