import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { selectUploadMediaStatus } from 'redux/features/createCampaignSlice/selectors';
import { useSelector } from 'react-redux';
import { uploadCampaignMedia } from 'redux/features/createCampaignSlice/createCampaignSlice';
// Components/ui
import {
  TextField as MuiTextField,
  Box as MuiBox,
  Typography as MuiTypography,
  InputAdornment,
  IconButton as MuiIconButton,
  styled,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// Icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// Utils
import { v4 as uuidv4 } from 'uuid';
// Types
import { UploadMedia } from 'redux/features/messagesSlice/types/UploadMedia';

const StyledTypography = styled(MuiTypography)({
  marginBottom: 1,
});

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledBox = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  marginTop: 1,
});

const StyledTextField = styled(MuiTextField)({
  width: '100%',
});

const FileFormat = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [filename, setFilename] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref for the input

  const isUploading = useSelector(selectUploadMediaStatus) === 'loading';

  const handleCharge = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const fileName = file.name;
        const newFormData = new FormData();
        const id = uuidv4();

        newFormData.append('file', file, fileName);

        const uploadData: UploadMedia = {
          file: newFormData,
          name: fileName,
          id,
          folderType: 'documents',
        };

        setFilename(fileName);

        const response = await dispatch(uploadCampaignMedia(uploadData));
        setFileUrl(response.payload.url);
      } catch (e) {
        console.error('Error uploading file: ', e);
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click(); // Trigger the input click
  };

  return (
    <>
      <StyledTypography variant="subtitle2">
        {t('campaigns.selectFile')}
      </StyledTypography>
      <StyledLoadingButton
        loading={isUploading}
        loadingPosition="start"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        size="small"
        color="success"
        onClick={handleButtonClick} // Trigger input click on button click
      >
        {t('global.charge')}
      </StyledLoadingButton>
      <input
        hidden
        ref={fileInputRef} // Assign ref to the input
        type="file"
        onChange={handleCharge}
      />
      {filename && (
        <StyledBox>
          <StyledTextField
            variant="outlined"
            size="small"
            value={filename}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MuiIconButton
                    onClick={() => window.open(fileUrl, '_blank')}
                    edge="end"
                  >
                    <CloudDownloadIcon />
                  </MuiIconButton>
                </InputAdornment>
              ),
            }}
          />
        </StyledBox>
      )}
    </>
  );
};

export default FileFormat;
