import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Utils
import { initRequestData } from 'redux/functions/initRequestData';
import listsService from './service';
// Types
import { IInitialState } from './types';
import { IList } from '@trii/types/dist/Contacts';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';

const initialState: IInitialState = {
  status: {
    fetch: 'idle',
  },
  data: [],
};

export const fetchLists = createAsyncThunk<IList[]>(
  'lists/fetchLists',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await listsService.fetchLists(URL_CONTACTS, jwtToken);

    return response;
  }
);

export const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    resetListsSliceState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLists.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchLists.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.data = action.payload;
      });
  },
});

// Actions
export const { resetListsSliceState } = listsSlice.actions;

export default listsSlice.reducer;
