import { useRef, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { uploadMedia } from 'redux/features/messagesSlice/messagesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
// Components/ui
import {
  TextField as MuiTextField,
  IconButton as MuiIconButton,
  Box as MuiBox,
  Typography as MuiTypography,
  styled,
} from '@mui/material';
import { ImageModal } from './components';
import { InsertEmoticon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import EmojiPicker from 'emoji-picker-react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// Utils
import { v4 as uuidv4 } from 'uuid';
import { selectUploadMediaStatus } from 'redux/features/createCampaignSlice/selectors';
import { uploadCampaignMedia } from 'redux/features/createCampaignSlice/createCampaignSlice';
import { UploadMedia } from 'redux/features/messagesSlice/types/UploadMedia';

const EmojiPickerBox = styled(MuiBox)({
  display: 'block',
  position: 'absolute',
  bottom: '73%',
  right: '0',
  zIndex: 1,
});

const StyledTypography = styled(MuiTypography)({
  marginBottom: 1,
});

const LoadingButtonBox = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  maxHeight: '4rem',
  height: '4rem',
});

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  height: 'max-content',
}));

const StyledTextField = styled(MuiTextField)({
  alignItems: 'center',
  marginTop: 1,
});

const StyledIconButton = styled(MuiIconButton)({
  color: 'text.disabled',
});

type ImageFormatProps = {
  title: string;
  chargeLabel: string;
  fieldLabel: string;
  type: 'image' | 'video';
};

const ImageFormat = ({ title, chargeLabel, fieldLabel, type }: ImageFormatProps) => {
  const dispatch = useAppDispatch();

  const [caption, setCaption] = useState<string>('');
  const [filename, setFilename] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);

  const isUploading = useSelector(selectUploadMediaStatus) === 'loading';
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref for input

  const handleChange = (value: string) => {
    setCaption(value);
  };

  const handleCharge = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const fileName = file.name;
        const newFormData = new FormData();
        const id = uuidv4();

        newFormData.append('photo', file, fileName);

        const uploadData: UploadMedia = {
          file: newFormData,
          name: fileName,
          id,
          folderType: type === 'image' ? 'images' : 'videos',
        };

        setFilename(fileName);

        const response = await dispatch(uploadCampaignMedia(uploadData));

        setFileUrl(response.payload.url);
      } catch (e) {
        console.error('Error uploading file: ', e);
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click(); // Trigger input click
  };

  return (
    <>
      {showEmojiPicker && (
        <EmojiPickerBox>
          <EmojiPicker height={300} onEmojiClick={(e) => console.log(e)} />
        </EmojiPickerBox>
      )}
      <StyledTypography variant="subtitle2">{title}</StyledTypography>
      <LoadingButtonBox>
        <StyledLoadingButton
          loading={isUploading}
          loadingPosition="start"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          size="small"
          color="success"
          onClick={handleButtonClick} // Trigger input click on button press
        >
          {chargeLabel}
        </StyledLoadingButton>
        <input
          hidden
          ref={fileInputRef} // Assign ref to input
          accept={type === 'image' ? 'image/*' : 'video/*'}
          type="file"
          onChange={handleCharge}
        />
        {fileUrl && <ImageModal imageUrl={fileUrl} altText={filename} />}
      </LoadingButtonBox>
      <StyledTextField
        multiline
        maxRows={5}
        fullWidth
        size="small"
        variant="outlined"
        value={caption}
        label={fieldLabel}
        onChange={(e) => handleChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <StyledIconButton
              size="small"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              <InsertEmoticon />
            </StyledIconButton>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default ImageFormat;
