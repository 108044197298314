import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { Select, Box, MenuItem, FormControl, InputLabel } from '@mui/material';
import { TemplateMessageViewer } from './components';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectTemplateById,
  selectTemplates,
  selectTemplatesFetchStatus,
} from 'redux/features/templatesSlice/utils/selectors';
import { useSelector } from 'react-redux';
import { fetchTemplates } from 'redux/features/templatesSlice/templatesSlice';
import { selectWhatsappTemplateSelectValue } from 'redux/features/createCampaignSlice/selectors';
import { setWhatsappTemplateSelectValue } from 'redux/features/createCampaignSlice/createCampaignSlice';
import { TemplatesContext } from 'context/Templates/TemplatesContext';

const TemplateMessage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { setSelectedTemplate, selectedTemplate } = useContext(TemplatesContext);

  const templatesFetchStatus = useSelector(selectTemplatesFetchStatus);
  const templates = useSelector(selectTemplates);
  const whatsappTemplateSelectValue = useSelector(selectWhatsappTemplateSelectValue);
  // const selectedTemplate = useSelector(
  // selectTemplateById(whatsappTemplateSelectValue)
  // );

  const handleChange = (id: string) => {
    dispatch(setWhatsappTemplateSelectValue(id));
    setSelectedTemplate(templates.find((template) => template.id === id));
  };

  useEffect(() => {
    if (templatesFetchStatus === 'idle') {
      dispatch(fetchTemplates('659471bf631be6db2f0f25fe'));
    }
  }, []);

  return (
    <Box marginTop={3}>
      <FormControl fullWidth size="small">
        <InputLabel id="template" shrink={true}>
          {t('campaigns.selectTemplate')}
        </InputLabel>
        <Select
          size="small"
          fullWidth
          labelId="template"
          defaultValue={whatsappTemplateSelectValue}
          value={whatsappTemplateSelectValue}
          label={t('campaigns.selectTemplate')}
          onChange={(e) => handleChange(e.target.value)}
          sx={{
            '& legend': {
              maxWidth: 'fit-content',
            },
            '& .MuiSvgIcon-root': {
              color: 'text.disabled',
            },
          }}
        >
          {templates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedTemplate && (
        <TemplateMessageViewer selectedTemplate={selectedTemplate} />
      )}
    </Box>
  );
};

export default TemplateMessage;
