import { useState, useCallback, useRef, useEffect, useLayoutEffect } from 'react';
import sanitizeHtml from 'sanitize-html';
// Components/ui
import { Box } from '@mui/material';
// Theme
import { useTheme } from '@mui/material/styles';
// Markdown
import { Markdown } from 'functions/Markdown';
// Components
import { Response, Templates, Variable } from './components';
import { TemplatesProvider } from 'context/Templates/TemplatesProvider';
import { useSearchParams } from 'react-router-dom';

type InputProps = {
  value: string;
  newEmoji: string;
  conversationId: string;
  contactId: string;
  maxHeigth?: string;
  setValue: (value: string) => void;
  setNewEmoji: (value: string) => void;
  handleSendMessage: (value: string) => void;
  disabled?: boolean;
  disableEnterKeyAction?: boolean;
};

const MENTION_REGEX = /@(\w*)$/g;
const SLASH_WITHOUT_SPACE_REGEX = /\/(\w*)$/g;
const SLASH_REGEX = /\/([^\/]*)\s*$/g;
const BACKSLASH_REGEX = /\\([^\\]*)\s*$/g;

const Input = ({
  value,
  newEmoji,
  conversationId,
  contactId,
  maxHeigth = null,
  setValue,
  setNewEmoji,
  handleSendMessage,
  disabled = false,
  disableEnterKeyAction = false,
}: InputProps) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLDivElement>(null);
  const [content, setContent] = useState<string>('');

  const [searchParams] = useSearchParams();
  const contactIdParam = searchParams.get('contactId');

  const [variableAnchorEl, setVariableAnchorEl] = useState<null | HTMLDivElement>(
    null
  );
  const [responseAnchorEl, setResponseAnchorEl] = useState<null | HTMLDivElement>(
    null
  );
  const [templatesAnchorEl, setTemplatesAnchorEl] = useState<null | HTMLDivElement>(
    null
  );
  const [variable, setVariable] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [templates, setTemplates] = useState<string>('');
  const [variableFocus, setVariableFocus] = useState<boolean>(false);
  const [caretPosition, setCaretPosition] = useState<number>(0);
  let variableLength = 0;

  const onContentChange = useCallback((evt) => {
    const sanitizeConf = {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'span',
        'strong',
        'em',
        'code',
        's',
        'u',
        'br',
      ]),
      allowedAttributes: {
        span: ['class', 'style'],
      },
      parseStyleAttributes: false,
    };

    return sanitizeHtml(evt.target.innerHTML, sanitizeConf);
  }, []);

  const checkAtSymbolBeforeCursor = (regexFunction: RegExp) => {
    const text = inputRef.current?.textContent || '';
    const textBeforeCursor = text.slice(0, caretPosition);

    const match = regexFunction.exec(textBeforeCursor);
    const isMatch =
      match && match.index + match[0].length === textBeforeCursor.length;
    return {
      isMatch,
      match,
    };
  };

  const getCaret = () => {
    let caretAt = 0;
    const sel = window.getSelection();

    if (sel.rangeCount === 0) {
      return caretAt;
    }

    const range = sel.getRangeAt(0);
    const preRange = range.cloneRange();
    preRange.selectNodeContents(inputRef.current);
    preRange.setEnd(range.endContainer, range.endOffset);
    const modifiedHtml = preRange.toString();

    caretAt = modifiedHtml.length;

    setCaretPosition(caretAt);
  };

  let positionFound = false;
  let text = 0;
  let caret = caretPosition;

  const searchCaret = (nodes: NodeListOf<ChildNode>, range: Range) => {
    nodes.forEach((node) => {
      if (node.childNodes.length > 0) {
        searchCaret(node.childNodes, range);
        return;
      }
      text += node.textContent.length;
      if (caretPosition <= text && caretPosition >= caret && !positionFound) {
        caret =
          caretPosition - caret === 0
            ? caretPosition > text
              ? text
              : caretPosition
            : caretPosition - caret;
        try {
          const newCaret =
            variableLength > 0 ? caretPosition + variableLength : caret;
          range.setStart(node, newCaret);
          range.collapse(true);
        } catch (error) {
          range.selectNodeContents(node);
          range.collapse(false);
        } finally {
          positionFound = true;
          variableLength = 0;
          return;
        }
      }
      if (!positionFound) {
        if (text > 0) {
          caret = text;
        } else {
          caret += 1;
        }
      }
    });
  };

  const setCaret = () => {
    const element = inputRef.current;
    if (element && element.childNodes.length > 0) {
      const sel = window.getSelection();
      if (sel.type !== 'Caret') {
        return;
      }
      const range = document.createRange();
      searchCaret(element.childNodes, range);

      sel.removeAllRanges();
      sel.addRange(range);
      element.focus();
    }
  };

  const applyRegex = (text: string) => {
    getCaret();
    setValue(text);

    if (!text.trim()) {
      setContent('');
      return;
    }
    const modifiedHTML = Markdown(text, true);
    setContent(modifiedHTML);
  };

  // const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
  //   getCaret();
  //   if (e.key === 'Enter' && !e.shiftKey) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     handleSendMessage(value);
  //     setContent('');
  //     return;
  //   }
  //   if (
  //     (variableAnchorEl || responseAnchorEl || templatesAnchorEl) &&
  //     (e.key === 'ArrowUp' || e.key === 'ArrowDown')
  //   ) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     setVariableFocus(true);
  //     return;
  //   }
  //   if (
  //     e.key === 'Escape' &&
  //     (variableAnchorEl || responseAnchorEl || templatesAnchorEl)
  //   ) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     handleClosePopover('!clear');
  //     return;
  //   }
  // };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    getCaret();
    if (e.key === 'Enter' && !e.shiftKey && !disableEnterKeyAction) {
      e.preventDefault();
      e.stopPropagation();
      handleSendMessage(value);
      setContent('');
      return;
    }
    if (e.key === 'Enter' && !e.shiftKey && disableEnterKeyAction) {
      e.preventDefault();
      e.stopPropagation();
      const input = inputRef.current;
      if (input) {
        const text = input.textContent || '';
        const textBeforeCursor = text.slice(0, caretPosition);
        const textAfterCursor = text.slice(caretPosition);
        const newText = textBeforeCursor + '\n' + textAfterCursor;
        applyRegex(newText);
        setCaretPosition(caretPosition + 1);
        return;
      }
    }
    if (
      (variableAnchorEl || responseAnchorEl || templatesAnchorEl) &&
      (e.key === 'ArrowUp' || e.key === 'ArrowDown')
    ) {
      e.preventDefault();
      e.stopPropagation();
      setVariableFocus(true);
      return;
    }
    if (
      e.key === 'Escape' &&
      (variableAnchorEl || responseAnchorEl || templatesAnchorEl)
    ) {
      e.preventDefault();
      e.stopPropagation();
      handleClosePopover('!clear');
      return;
    }
    // Close the popover when the space key is pressed
    if (
      e.key === ' ' &&
      (variableAnchorEl || responseAnchorEl || templatesAnchorEl)
    ) {
      e.preventDefault();
      e.stopPropagation();
      handleClosePopover('!clear');

      const input = inputRef.current;

      if (input) {
        const text = input.textContent || '';
        const textBeforeCursor = text.slice(0, caretPosition);
        const textAfterCursor = text.slice(caretPosition);
        const newText = textBeforeCursor + ' ' + textAfterCursor;
        applyRegex(newText);
        setCaretPosition(caretPosition + 1);
        return;
      }

      return;
    }
  };

  const handleFocus = () => {
    if (variableFocus) {
      setVariableFocus(false);

      inputRef.current?.focus();
    }
  };

  const handleClosePopover = (clearContent?: '!clear') => {
    setVariableAnchorEl(null);
    setResponseAnchorEl(null);
    setTemplatesAnchorEl(null);
    setVariableFocus(false);
    setResponse('');
    setVariable('');
    setTemplates('');
    if (clearContent !== '!clear') setContent('');
  };

  const handleSelectOption = (regex: RegExp) => (variable: string) => {
    const input = inputRef.current;
    if (input) {
      const text = input.textContent || '';
      const textBeforeCursor = text.slice(0, caretPosition);
      const textAfterCursor = text.slice(caretPosition);
      const match = textBeforeCursor.match(regex);

      if (match) {
        const mentionText = match[0];
        const newVariable = mentionText.replace(regex, variable);

        const newText =
          textBeforeCursor.replace(regex, newVariable) + textAfterCursor;
        applyRegex(newText);
        handleClosePopover();
        variableLength = variable.length - 1;
        setCaretPosition(variableLength + caretPosition);
        setTimeout(() => {
          const input = inputRef.current;
          input.scrollTop = input.scrollHeight;
        }, 0);
      }
    }
  };

  useLayoutEffect(() => {
    if ((!content && value) || content !== value) {
      const modifiedHTML = Markdown(value, true);
      setContent(modifiedHTML);
    }
  }, [value]);

  useEffect(() => {
    const input = inputRef.current;
    if (input && !contactIdParam) {
      input.focus();
    }
  }, []);

  useEffect(() => {
    const isVariable = checkAtSymbolBeforeCursor(MENTION_REGEX);
    const isResponse = checkAtSymbolBeforeCursor(SLASH_WITHOUT_SPACE_REGEX);

    const isTemplates = checkAtSymbolBeforeCursor(BACKSLASH_REGEX);
    if (isVariable.isMatch) {
      setVariableAnchorEl(inputRef.current);
      setVariable(isVariable.match[1]);
    } else {
      setVariableAnchorEl(null);
    }
    if (isResponse.isMatch) {
      setResponseAnchorEl(inputRef.current);
    }

    if (isTemplates.isMatch) {
      setTemplatesAnchorEl(inputRef.current);
    } else {
      setVariableAnchorEl(null);
    }

    if (caretPosition) {
      setCaret();
    }
  }, [caretPosition, content]);

  useEffect(() => {
    if (newEmoji) {
      const input = inputRef.current;
      input.focus();
      const sel = window.getSelection();
      if (input && sel) {
        // Obtener la posición del caret antes de insertar el emoji
        const range = sel.getRangeAt(0);
        const emojiNode = document.createTextNode(newEmoji);

        // Insertar el emoji en la posición actual del caret
        range.insertNode(emojiNode);

        // Mover el caret justo después del emoji insertado
        range.setStartAfter(emojiNode);
        range.setEndAfter(emojiNode);
        sel.removeAllRanges();
        sel.addRange(range);

        // Actualizar la posición del caret al final del contenido
        setCaretPosition(input.textContent.length);

        setNewEmoji('');

        // Aplicar el regex para procesar el nuevo contenido
        applyRegex(input.textContent || '');
      }
    }
  }, [newEmoji]);

  useEffect(() => {
    const variableMatch = value.match(MENTION_REGEX);

    if (variableMatch) {
      const mentionMatch = variableMatch[0].replace(
        MENTION_REGEX,
        (match, variable) => {
          return variable;
        }
      );
      setVariable(mentionMatch);
    } else {
      setVariable('');
    }

    if (Boolean(responseAnchorEl)) {
      const responseMatch = value.match(SLASH_REGEX);
      if (responseMatch) {
        const response = checkAtSymbolBeforeCursor(SLASH_REGEX);
        setResponse(response.match[1]);
      } else {
        setResponse('');
      }
    }
    if (Boolean(templatesAnchorEl)) {
      const templatesMatch = value.match(BACKSLASH_REGEX);
      if (templatesMatch) {
        const templates = checkAtSymbolBeforeCursor(BACKSLASH_REGEX);
        setTemplates(templates.match[2]);
      } else {
        setTemplates('');
      }
    }
  }, [value]);

  return (
    <>
      <style>
        {`
        .emoji {
          display: inline-block;
          color: transparent;
          background-size: 1rem;
          background-repeat: no-repeat;
          background-position: center;
          caret-color: ${theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
          
        }

        .children-emoji::selection {
          background-color: rgba(150,150,150,.4);
        }
        `}
      </style>

      <Box
        id="conversation-input"
        onInput={(e) => applyRegex(e.currentTarget.textContent)}
        onBlur={onContentChange}
        ref={inputRef}
        role="textbox"
        dir="ltr"
        sx={{
          width: 'inherit',
          maxWidth: '100%',
          padding: '8px',
          minHeight: '2rem',
          maxHeight: maxHeigth ? maxHeigth : '6rem',
          overflowY: 'auto',
          whiteSpace: 'pre-wrap',
          fontSize: '.9rem',
          overflowWrap: 'anywhere',
          '&[contenteditable=true]:empty:before': {
            content: 'attr(placeholder)',
            color: (theme) => theme.palette.text.secondary,
            cursor: 'text',
          },
          borderRadius: '0.5rem',
          bgcolor: (theme) => `${theme.palette.grey[500]}50`,
          '&:focus': {
            outline: 'none',
          },
        }}
        contentEditable={!disabled}
        dangerouslySetInnerHTML={{ __html: content }}
        suppressHydrationWarning
        onKeyDown={(e) => handleKeyDown(e)}
        onFocus={getCaret}
        onClick={getCaret}
        // placeholder={t('conversations.message.messagePlaceholder')}
      />
      {variableAnchorEl && (
        <Variable
          variable={variable}
          anchorEl={variableAnchorEl}
          caretPosition={caretPosition}
          autoFocus={variableFocus}
          conversationId={conversationId}
          contactId={contactId}
          handleClose={handleClosePopover}
          handleFocus={handleFocus}
        />
      )}
      {responseAnchorEl && (
        <Response
          variable={response}
          anchorEl={responseAnchorEl}
          caretPosition={caretPosition}
          autoFocus={variableFocus}
          handleClose={handleClosePopover}
          onSelect={handleSelectOption(SLASH_REGEX)}
          handleFocus={handleFocus}
        />
      )}
      {templatesAnchorEl && (
        <TemplatesProvider>
          <Templates
            variable={templates}
            anchorEl={templatesAnchorEl}
            caretPosition={caretPosition}
            handleClose={handleClosePopover}
            onSelect={handleSelectOption(BACKSLASH_REGEX)}
            handleSendMessage={handleSendMessage}
          />
        </TemplatesProvider>
      )}
    </>
  );
};

export default Input;
