import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { fetchCampaigns, selectFilterStatus, selectFilterType, setFilterRange, selectFilterToDate, selectFilterFromDate} from 'redux/features/campaignSlice/campaignSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import SelectDatePersonalized from '../SelectDatePersonalized/SelectDatePersonalized';
const SelectDate = () => {
  const [periodo, setPeriodo] = React.useState<string>('');
  const [showPersonalized, setShowPersonalized] = React.useState<boolean>(false);
  const currentFilterStatus = useSelector(selectFilterStatus);
  const currentFilterType = useSelector(selectFilterType);
  const currentFilterFromDate =useSelector(selectFilterFromDate)
  const currentFilterToDate =useSelector(selectFilterToDate)
  const dispatch = useAppDispatch()
  const filtrarPorRange = (range: number) => {
    if(range == 0){
      setShowPersonalized(true);
      dispatch(setFilterRange(null));
    }else {
      setShowPersonalized(false)
      dispatch(setFilterRange(range));
      dispatch(fetchCampaigns({ status: currentFilterStatus, type: currentFilterType, range, fromDate: currentFilterFromDate, toDate:currentFilterToDate }));
    }
    
  };
  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value; 
    setPeriodo(value);
    
    filtrarPorRange(Number(value));
  };

  return (
    <Box>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Periodo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={periodo}
            label="Periodo"
            onChange={handleChange}
          >
            <MenuItem value={1}>Día</MenuItem>
            <MenuItem value={7}>Semana</MenuItem>
            <MenuItem value={30}>Mes</MenuItem>
            <MenuItem value={365}>Año</MenuItem>
            <MenuItem value={77}>Últimos 7 días</MenuItem>
            <MenuItem value={3030}>Últimos 30 días</MenuItem>
            <MenuItem value={60}>Últimos 60 días</MenuItem>
            <MenuItem value={0}>Personalizado</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {showPersonalized && <SelectDatePersonalized/>}
    </Box>
  );
}
 export default SelectDate;

