import { Box, Button, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreateButtons = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
//   const openPanel = () => {
//     setOpenPanelCreateDifussion(true);
// };

const handleCreateCampaign = () => {
  navigate('/a/marketing/newCampaign');
};


  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Button
        size="small"  // Coincide con el tamaño de los otros botones
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}
        sx={{
          mb: "10px",
          width: "100%",  // Ajusta el ancho para que sea similar al de los otros botones
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={handleCreateCampaign}

      >
        {t('marketingView.createCampaign')}
      </Button>
    </Box>
  );
}

export default CreateButtons