import { Box, Button, ToggleButtonGroup, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchCampaigns, setFilterStatus,  selectFilterStatus, selectFilterType, selectFilterRange, selectFilterFromDate, selectFilterToDate } from 'redux/features/campaignSlice/campaignSlice';
import { useDispatch, useSelector } from 'react-redux';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: "4px",
    },
    '&:first-of-type': {
      borderRadius: "4px",
    },
  },
}));

const FilterButtons = () => {
  const currentFilterType = useSelector(selectFilterType);
  const currentFilterRange = useSelector(selectFilterRange);
  const currentFilterFromDate =useSelector(selectFilterFromDate);
  const currentFilterToDate =useSelector(selectFilterToDate);
  const theme = useTheme()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()
  const handleFilterClick = (status:string) => {
    dispatch(setFilterStatus(status));
    dispatch(fetchCampaigns({ 
      status, 
      type: currentFilterType, 
      range: currentFilterRange, 
      fromDate: currentFilterFromDate, 
      toDate:currentFilterToDate }));
  };
  // const handleFilterClick = (filterStatus: number) => {
  //   dispatch(setFilterStatus(filterStatus));
  //   dispatch(fetchCampaigns(filterStatus));  
  // };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}
        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick('design')}
      >
        {t("marketingView.design")}
      </Button>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}

        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick('active')}
      >
        {t("marketingView.inProgress")}
      </Button>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}

        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick('finalized')}
      >
        {t("marketingView.finished")}

      </Button>
    </Box>
  );
}

export default FilterButtons