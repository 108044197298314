import { useTranslation } from 'react-i18next';
// Context
import { useContext } from 'react';
import { TemplatesContext } from 'context/Templates/TemplatesContext';
// Components/ui
import { Box, Typography, useTheme } from '@mui/material';
import { Message } from './components';
import { style } from './style';
// Images
import bgLight from './assets/images/bg-light.png';
import bgDark from './assets/images/bg-dark.png';
// Types
import { TemplatesList } from 'context/Templates/types/Lists';

type MessagePreviewProps = {
  selectedTemplate: TemplatesList;
};
const MessagePreview = ({ selectedTemplate }: MessagePreviewProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { focusType } = useContext(TemplatesContext);

  const image = theme.palette.mode === 'light' ? bgLight : bgDark;

  return (
    <Box
      sx={{
        ...style.container,
        //@ts-ignore
        backgroundColor: theme.palette.background.panelCenter,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography sx={{ marginBottom: '5px' }}>
        {t('conversations.message.preview')}
      </Typography>
      <Box
        sx={{
          ...style.previewContainer,
          backgroundImage: `url(${image})`,
          backgroundColor: theme.palette.mode == 'dark' ? '' : '#F0EBE3',
        }}
      >
        {selectedTemplate && (
          <Message selectedTemplate={selectedTemplate} focusType={focusType} />
        )}
      </Box>
    </Box>
  );
};

export default MessagePreview;
