import React from 'react';
// Redux
import { useSelector } from 'react-redux';
import {
  selectScheduleData,
  selectScheduleStatus,
  // @ts-ignore
} from 'redux/features/scheduleSlice/scheduleSlice';
// Components/ui
import { Box } from '@mui/material';
import ScheduleItem  from './components/ScheduleItem/ScheduleItem';
import ScheduleItemSkeleton from './components/ScheduleItemSkeleton/ScheduleItemSkeleton';

export default function ScheduleContent() {
  const scheduleData = useSelector(selectScheduleData);
  const scheduleStatus = useSelector(selectScheduleStatus);

  const Schedule =
    scheduleStatus === 'succeeded' ? (
      scheduleData.map((schedule) => (
        <ScheduleItem key={schedule.id} data={schedule} />
      ))
    ) : (
      <ScheduleItemSkeleton />
    );

  return (
    <Box sx={{ maxHeight: '90%' }}>
      <Box
        sx={{
          py: 2,
          px: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          maxHeight: 'calc(90vh - 64px - 48px)',
          overflowY: 'auto',
        }}
      >
        {Schedule}
      </Box>
    </Box>
  );
}
