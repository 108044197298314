// components/ui
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
const Title = () => {
  const {t} = useTranslation()
  return (
    <Box sx={{display: "flex", alignItems: "center", mb: "20px"}}>
      <Typography
        color={"text.primary"}
        fontSize={15}
        fontWeight={500}
      >
        Ver por tipo
      </Typography>
    </Box>
  );
};

export default Title;
