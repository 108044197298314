import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components/iu
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box as MuiBox,
  styled,
  TextareaAutosize,
} from '@mui/material';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { useSelector } from 'react-redux';
import { selectDraftingTextareaValue } from 'redux/features/createCampaignSlice/selectors';
import {
  setDraftingTextareaValue,
  setSavedDraftingTextareaValue,
} from 'redux/features/createCampaignSlice/createCampaignSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
`
);

interface ModalDialogProps {
  isOpen: boolean;
  onClose: () => void;
  setIsEditing: (expanded: boolean) => void;
  title: string;
}

const ModalDialog: React.FC<ModalDialogProps> = ({
  isOpen,
  onClose,
  title,
  setIsEditing,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [textAreaValue, setTextAreaValue] = useState('');

  const draftingTextAreaValue = useSelector(selectDraftingTextareaValue);

  const handleSave = () => {
    dispatch(setSavedDraftingTextareaValue(textAreaValue));
    setIsEditing(false);
    onClose();
  };

  const handleMinimize = () => {
    onClose();
    dispatch(setDraftingTextareaValue(draftingTextAreaValue));
  };

  useEffect(() => {
    if (isOpen) {
      setTextAreaValue(draftingTextAreaValue);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title}
        <MuiBox>
          <IconButton color="primary" onClick={handleMinimize}>
            <MinimizeIcon />
          </IconButton>
        </MuiBox>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 550 }}>
        <Textarea
          aria-label="minimum height"
          minRows={28}
          maxRows={28}
          sx={{ resize: 'none', width: '100%' }}
          value={textAreaValue}
          onChange={(event) => setTextAreaValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('global.cancel')}</Button>
        <Button variant="contained" onClick={handleSave}>
          {t('global.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
