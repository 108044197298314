import { INavIcon } from '../types/types';

import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';
import AssessmentIcon from '@mui/icons-material/Assessment';

export const iconsData: INavIcon[] = [
  {
    title: 'Dashboard',
    to: '/a/marketing/dashboard',
    icon: <DashboardIcon />,
    isDevelopment: true,
  },
  {
    title: 'navBar.marketing',
    to: '/a/marketing/Marketing',
    icon: <CampaignIcon />,
    isDevelopment: true,
  },
  {
    title: 'navBar.reports',
    to: '/a/marketing/reports',
    icon: <AssessmentIcon />,
    isDevelopment: true,
  },
  {
    title: 'navBar.system',
    to: '/a/marketing/System',
    icon: <SettingsIcon />,
    isDevelopment: true,
  },
];
