// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='checkbox']:checked + label,
[type='checkbox']:not(:checked) + label {
  position: relative;
  padding-right: 55px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type='checkbox']:checked + label:before,
[type='checkbox']:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #666;
  background: #fff;
}

[type='checkbox']:checked + label:after,
[type='checkbox']:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #222222;
  position: absolute;
  top: 6px;
  right: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='checkbox']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailEditor/ui/Checkbox.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;EAClB,aAAa;AACf;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;EACrB,WAAW;AACb;;AAEA;;EAEE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;EACX,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,iCAAiC;EACjC,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B,mBAAmB;AACrB","sourcesContent":["[type='checkbox']:checked,\n[type='checkbox']:not(:checked) {\n  position: absolute;\n  left: -9999px;\n}\n\n[type='checkbox']:checked + label,\n[type='checkbox']:not(:checked) + label {\n  position: relative;\n  padding-right: 55px;\n  cursor: pointer;\n  line-height: 20px;\n  display: inline-block;\n  color: #666;\n}\n\n[type='checkbox']:checked + label:before,\n[type='checkbox']:not(:checked) + label:before {\n  content: '';\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 18px;\n  height: 18px;\n  border: 1px solid #666;\n  background: #fff;\n}\n\n[type='checkbox']:checked + label:after,\n[type='checkbox']:not(:checked) + label:after {\n  content: '';\n  width: 8px;\n  height: 8px;\n  background: #222222;\n  position: absolute;\n  top: 6px;\n  right: 6px;\n  -webkit-transition: all 0.2s ease;\n  transition: all 0.2s ease;\n}\n\n[type='checkbox']:not(:checked) + label:after {\n  opacity: 0;\n  -webkit-transform: scale(0);\n  transform: scale(0);\n}\n\n[type='checkbox']:checked + label:after {\n  opacity: 1;\n  -webkit-transform: scale(1);\n  transform: scale(1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
