import { useContext } from "react";
// Context
import { StyleContext } from "style/styleProvider";
// Redux
import { useSelector } from "react-redux";

// Components/ui
import { TuneButton, Column } from "./components";
import { TableHead, Checkbox, TableCell, TableRow, Theme } from "@mui/material";
import { ContactBusinessTableContext } from "features/Views/MarketingView/context/ContactBusinessTableContext";


const columnDialog = [
  {
    title: "Estado",
    visible: true,
  },
  {
    title: "Nombre",
    visible: true,

  },
  {
    title: "Destinatarios",
    visible: true,

  },
  {
    title: "Entregados",
    visible: true,

  },
  {
    title: "Aperturas(%)",
    visible: true,
  },
  {
    title: "Clicks",
    visible: true,
  },
  {
    title: "Fecha de envío",
    visible: true,
  },
  {
    title: "Acciones",
    visible: true,
  },
]

const Head = () => {
  const { color } = useContext(StyleContext);
  const { handleParentCheck, mode } = useContext(ContactBusinessTableContext);
  // const columns = useSelector(selectContactFields);


  const AtributeColumns = columnDialog.map(
    (column, i) => column.visible && <Column key={i} data={column} />
  );



  return (
    <TableHead
      sx={{
        //@ts-ignore
        backgroundColor: (theme: Theme) => theme.palette.background.panelHeader,
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}
    >
      {columnDialog.length > 0 && (
        <TableRow>
          { AtributeColumns}
        </TableRow>
      )}
    </TableHead>
  );
};

export default Head;
