import axios from 'axios';
// Utils
import getRequestConfig from 'redux/functions/getRequestConfig';
import { IList } from '@trii/types/dist/Contacts';

async function fetchLists(URL_CONTACTS: string, jwtToken: string) {
  const config = getRequestConfig.contentTypeJson(jwtToken);

  try {
    const response = await axios.get<IList[]>(`${URL_CONTACTS}/Lists`, config);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export default {
  fetchLists,
};
