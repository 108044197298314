import React, { useContext } from 'react';
// @ts-ignore
import { InitScheduleContext } from 'features/Views/Schedules/context/ScheduleContext';
import { useTranslation } from 'react-i18next';
// Types
// @ts-ignore
import { ScheduleContext } from 'features/Views/Schedules/context/types';
// Components/ui
import { Button, Box, Typography, Breadcrumbs } from '@mui/material';
import { StyleContext } from 'style/styleProvider';
import { NavLink } from 'react-router-dom';

export default function ScheduleHeader() {
  const { t } = useTranslation();
  const { openCreatePanel, createPanelOpen } =
    useContext<ScheduleContext>(InitScheduleContext);
  const { fontSize, fontWeight } = useContext(StyleContext);
  const breadcrumbs = [
    <NavLink key="1" to="/a/conversations/system">
      <Typography color="secondary">{t('settingsView.settings')}</Typography>
    </NavLink>,
    <NavLink key="2" to="/a/conversations/system/schedule">
      <Typography color="secondary">{t('scheduleView.schedules')}</Typography>
    </NavLink>,
  ];
  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      alignItems={'center'}
      sx={{ paddingRight: 2 }}
      borderRadius={'4px'}
      height="50px"
         // className="m-0"
        gap={'1rem'}

    >
      <Box position={'absolute'} left="2rem" top="1rem">
        <Breadcrumbs separator="-" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Button
        sx={{ alignSelf: 'center', bgcolor: 'primary.main' }}
        variant="contained"
        size="small"
        onClick={openCreatePanel}
        disabled={createPanelOpen}
      >
        {t('scheduleView.createSchedule')}
      </Button>
    </Box>
  );
}
