import axios from 'axios';
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
import { RootState } from '../../store';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { InitialState, EditScheduleData } from './types';
import { ISchedule } from '@trii/types/dist/Common/Schedules';

//service

import scheduleSliceService from './scheduleSliceService';


const initialState: InitialState = {
  status: {
    fetch: 'idle',
    create: 'idle',
    delete: 'idle',
    edit: 'idle',
  },
  data: [],
  selectedSchedule: null,
};

export const fetchSchedules = createAsyncThunk<ISchedule[]>(
  'schedules/fetchSchedules',
  async (_, { dispatch }) => {
    const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;
    const schedules = await scheduleSliceService.fetchSchedulesService(jwtToken, URL_MARKETING);
    return schedules;
  }
);

export const fetchScheduleByName = createAsyncThunk(
  'schedules/fetchScheduleByName',
  async (name: string, { dispatch }) => {
    const { jwtToken, URL_MARKETING} = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

    // Llama al servicio de schedules por nombre
    const schedule = await scheduleSliceService.fetchScheduleByNameService(jwtToken,URL_MARKETING, name);
    return schedule;
  }
)

export const createSchedule = createAsyncThunk(
  'schedules/createSchedule',
  async (newSchedule: ISchedule, { dispatch }) => {
    const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

    const schedule = await scheduleSliceService.createScheduleService(jwtToken, URL_MARKETING, newSchedule);
    return schedule;
  }
);

export const deleteSchedule = createAsyncThunk(
  'schedules/deleteSchedule',
  async (scheduleId: string, { dispatch }) => {
    const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

    await scheduleSliceService.deleteScheduleService(jwtToken, URL_MARKETING, scheduleId);
    return scheduleId;
  }
);

export const editSchedule = createAsyncThunk(
  'schedules/editSchedule',
  async (editData: EditScheduleData, { dispatch }) => {
    const { scheduleId, editedSchedule } = editData;
    const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

    await scheduleSliceService.editScheduleService(jwtToken, URL_MARKETING, scheduleId, editedSchedule);

    return editData;
  }
);

export const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchedules.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchSchedules.rejected, (state, action) => {
        state.status.fetch = 'idle';
      })
      .addCase(createSchedule.pending, (state) => {
        state.status.create = 'loading';
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        state.status.create = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(deleteSchedule.pending, (state, action) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        const deletedScheduleId = action.payload;
        state.status.delete = 'succeeded';
        state.data = state.data.filter(
          (schedule) => schedule.id !== deletedScheduleId
        );
      })
      .addCase(editSchedule.pending, (state) => {
        state.status.edit = 'loading';
      })
      .addCase(editSchedule.rejected, (state) => {
        state.status.edit = 'rejected';
      })
      .addCase(editSchedule.fulfilled, (state, action) => {
        const { scheduleId, editedSchedule } = action.payload;
        const editedScheduleIndex = state.data.findIndex(
          (schedule) => schedule.id === scheduleId
        );
        state.data[editedScheduleIndex] = editedSchedule;
        state.status.edit = 'succeeded';
      })
      .addCase(fetchScheduleByName.pending, (state, action) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchScheduleByName.fulfilled, (state, action) => {
        state.selectedSchedule = action.payload;
        state.status.fetch = 'succeeded';
      });
  },
});

const selectSchedule = (state: RootState) => state.Schedule;
export const selectScheduleData = createSelector(
  selectSchedule,
  (schedules: InitialState) => schedules.data
);
export const selectScheduleStatus = createSelector(
  selectSchedule,
  (schedules: InitialState) => schedules.status.fetch
);
export const selectContactsStatus = createSelector(
  selectSchedule,
  (schedules: InitialState) => schedules.status.fetch
);
export const selectScheduleCreateStatus = createSelector(
  selectSchedule,
  (schedules: InitialState) => schedules.status.create
);
export const selectScheduleDeleteStatus = createSelector(
  selectSchedule,
  (schedules: InitialState) => schedules.status.delete
);
export const selectScheduleEditStatus = createSelector(
  selectSchedule,
  (schedules: InitialState) => schedules.status.edit
);
export const selectedSchedule = createSelector(
  selectSchedule,
  (schedules: InitialState) => schedules.selectedSchedule
);

export const selectScheduleById = (scheduleId: string) =>
  createSelector(
    selectSchedule,
    (schedules) => schedules.data.find((schedule) => schedule.id === scheduleId)
  );

export default schedulesSlice.reducer;
