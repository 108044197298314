import axios from 'axios';
import getRequestConfig from 'redux/functions/getRequestConfig';
import { 
    PerformanceDashboard, 
    ReportingPanelDashboard, 
    RecipientsDashboard, 
    DeliveriesDashboard, 
    ActivityOverTimeDashboard 
} from "@trii/types/dist/Marketing";


const fetchPerformanceData = async (
    jwtToken: string,
    URL: string
): Promise<PerformanceDashboard> => {
    const config = getRequestConfig.contentTypeJson(jwtToken);
    
    try {
        const response = await axios.post<PerformanceDashboard>(
            `${URL}/Dashboard/performance`, 
            {}, 
            config
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

const fetchReportingPanelData = async (
    jwtToken: string,
    URL: string
): Promise<ReportingPanelDashboard> => {
    const config = getRequestConfig.contentTypeJson(jwtToken);
    
    try {
        const response = await axios.post<ReportingPanelDashboard>(
            `${URL}/Dashboard/reporting-panel`, 
            {}, 
            config
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

const fetchRecipientsData = async (
    jwtToken: string,
    URL: string
): Promise<RecipientsDashboard[]> => {
    const config = getRequestConfig.contentTypeJson(jwtToken);
    
    try {
        const response = await axios.post<RecipientsDashboard[]>(
            `${URL}/Dashboard/recipients`, 
            {}, 
            config
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

const fetchDeliveriesData = async (
    jwtToken: string,
    URL: string
): Promise<DeliveriesDashboard> => {
    const config = getRequestConfig.contentTypeJson(jwtToken);
    
    try {
        const response = await axios.post<DeliveriesDashboard>(
            `${URL}/Dashboard/deliveries`, 
            {}, 
            config
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

const fetchActivityOverTimeData = async (
    jwtToken: string,
    URL: string
): Promise<ActivityOverTimeDashboard> => {
    const config = getRequestConfig.contentTypeJson(jwtToken);
    
    try {
        const response = await axios.post<ActivityOverTimeDashboard>(
            `${URL}/Dashboard/activity-over-time`, 
            {}, 
            config
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};



const dashboardSliceService = {
    fetchPerformanceData,
    fetchReportingPanelData,
    fetchRecipientsData,
    fetchDeliveriesData,
    fetchActivityOverTimeData,
};

export default dashboardSliceService;