import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
// Context
import { TemplatesContext } from 'context/Templates/TemplatesContext';
// Components/ui
import { Box, Divider, Typography, Button } from '@mui/material';
import { VariableButtonInput, VariableInput } from './components';
import { style } from './style';
// Utils
import { getHeaderContent } from 'functions/getHeaderContent';
import { TemplateHeaderType } from '@trii/types/dist/Common/Messages';

const Text: React.FC = () => {
  const { t } = useTranslation();
  const {
    localBodyVariables,
    setLocalBodyVariables,
    localHeaderVariables,
    setLocalHeaderVariables,
    localButtons,
    setLocalButtons,
    selectedTemplate,
  } = useContext(TemplatesContext);

  const [formatedBodyMessage, setFormatedBodyMessage] = useState<string>('');
  const [formatedHeaderMessage, setFormatedHeaderMessage] = useState<string>('');

  function formatBodyMessage(variableName: string, newValue: string) {
    const newMessage = selectedTemplate.body.replace(variableName, newValue);

    setFormatedBodyMessage(newMessage);
  }

  function formatHeaderMessage(variableName: string, newValue: string) {
    const newMessage = selectedTemplate.header.text.replace(variableName, newValue);

    setFormatedHeaderMessage(newMessage);
  }

  function handleHeaderVariableChange(variableName: string, newValue: string) {
    const newLocalHeaderVariables = localHeaderVariables.map((variable) => {
      if (variable.name === variableName) {
        return { ...variable, value: newValue };
      }
      return variable;
    });

    setLocalHeaderVariables(newLocalHeaderVariables);
    formatHeaderMessage(variableName, newValue);
  }

  function handleBodyVariableChange(variableName: string, newValue: string) {
    const newLocalBodyVariables = localBodyVariables.map((variable) => {
      if (variable.name === variableName) {
        return { ...variable, value: newValue };
      }
      return variable;
    });

    setLocalBodyVariables(newLocalBodyVariables);
    formatBodyMessage(variableName, newValue);
  }

  function handleButtonVariableChange(
    buttonNumber: number,
    variableName: string,
    newValue: string
  ) {
    const newLocalButtonsVariables = localButtons.map((button) => {
      if (button.btnNumber === buttonNumber) {
        const newVars = button.vars.map((variable) => {
          if (variable.name === variableName) {
            return { ...variable, value: newValue };
          }
          return variable;
        });
        return { ...button, vars: newVars };
      }
      return button;
    });
    setLocalButtons(newLocalButtonsVariables);
  }

  function clearUpState() {
    setLocalBodyVariables(null);
    setLocalHeaderVariables(null);
    setLocalButtons(null);
    setFormatedBodyMessage('');
    setFormatedHeaderMessage('');
  }

  useEffect(() => {
    return clearUpState;
  }, []);

  return (
    <Box
      sx={{
        ...style.container,
        color: (theme) => theme.palette.text.primary,
        cursor: 'pointer',
      }}
    >
      <Box
        width={'100%'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
        }}
      >
        {/* Preview header */}
        <Box
          sx={{
            fontWeight: 'bold',
          }}
        >
          {!selectedTemplate?.header
            ? ' '
            : selectedTemplate.header.type === TemplateHeaderType.TEXT &&
              formatedHeaderMessage !== ''
            ? formatedHeaderMessage
            : getHeaderContent(selectedTemplate.header)}
        </Box>
        {/* Preview body */}
        {formatedBodyMessage !== '' ? formatedBodyMessage : selectedTemplate?.body}
        {/* Preview footer */}
        <Box
          sx={{
            color: (theme) => theme.palette.text.secondary,
            marginBottom: '10px',
          }}
        >
          {selectedTemplate?.footer ? selectedTemplate?.footer : ''}
        </Box>
        {/* Preview buttons */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {selectedTemplate.buttons?.map((button, index) => (
            <Button size="small" key={index} variant="outlined">
              {button.text}
            </Button>
          ))}
        </Box>
      </Box>
      <Divider />

      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ marginRight: '10px', width: '85%', gap: '10px' }}
      >
        <Typography variant="caption">{t('global.header')}</Typography>
        <Divider />
        {localHeaderVariables?.map((variable, index) => (
          <VariableInput
            key={index}
            handleChange={handleHeaderVariableChange}
            variableName={variable.name}
            variableValue={variable.value}
          />
        ))}
        <Typography variant="caption">{t('global.body')}</Typography>
        <Divider />
        {localBodyVariables?.map((variable, index) => (
          <VariableInput
            key={index}
            handleChange={handleBodyVariableChange}
            variableName={variable.name}
            variableValue={variable.value}
          />
        ))}
        <Typography variant="caption">{t('global.buttons')}</Typography>
        <Divider />
        {localButtons?.map((variable) =>
          variable.vars.map((buttonVar, index) => (
            <VariableButtonInput
              key={index}
              handleChange={handleButtonVariableChange}
              variableName={buttonVar.name}
              variableValue={buttonVar.value}
              buttonNumber={variable.btnNumber}
            />
          ))
        )}
      </Box>
    </Box>
  );
};

export default Text;
