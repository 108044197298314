import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
// Types
import { RootState } from '../../store';
import { ISpaceInfo } from '@trii/types/dist/Spaces';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { SpaceSliceState } from './types/SpaceSliceState';
import { RequestStatus } from 'redux/types/RequestStatus';
// Service
import spaceSliceService from './spaceSliceService';

const initialState: SpaceSliceState = {
  spaceInfo: null,
  URL: {
    USER: 'https://agent-api.trii.app/api/v1/settings/user',
    USERS: '',
    CONVERSATIONS: '',
    CONTACTS: '',
    MEDIA: '',
    MESSAGES: '',
    CALENDAR: '',
    MARKETING: '',
    TEMPLATES: '',
  },
  status: {
    fetch: 'idle',
  },
};

export const fetchSpaceInfo = createAsyncThunk(
  'space/getSpaceInfo',
  async (_, { dispatch }) => {
    const { jwtToken } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await spaceSliceService.fetchSpaceInfo(jwtToken);

    return response.data;
  }
);

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setSpaceInfo: (state, action: PayloadAction<ISpaceInfo>) => {
      state.spaceInfo = action.payload;
    },
    setSpaceFetchStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.status.fetch = action.payload;
    },
    setSpaceUrls: (state, action: PayloadAction<string>) => {
      state.URL.CONVERSATIONS = `https://agent-api.trii.app/api/v1/conversations/${action.payload}`;
      state.URL.USERS = `https://agent-api.trii.app/api/v1/settings/${action.payload}`;
      state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${action.payload}`;
      state.URL.MEDIA = `https://agent-api.trii.app/api/v1/media/${action.payload}`;
      state.URL.MESSAGES = `https://agent-api.trii.app/api/v1/messages/${action.payload}`;
      state.URL.CALENDAR = `https://agent-api.trii.app/api/v1/calendar/${action.payload}`;
      state.URL.MARKETING = `https://agent-api.trii.app/api/v1/marketing/${action.payload}`;
      state.URL.TEMPLATES = `https://agent-api.trii.app/api/v1/conversations/${action.payload}/TemplateMessages`;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpaceInfo.pending, (state) => {
      state.status.fetch = 'loading';
    });
    builder.addCase(
      fetchSpaceInfo.fulfilled,
      (state, action: PayloadAction<ISpaceInfo>) => {
        state.spaceInfo = action.payload;
        state.URL.CONVERSATIONS = `https://agent-api.trii.app/api/v1/conversations/${action.payload.id}`;
        state.URL.USERS = `https://agent-api.trii.app/api/v1/settings/${action.payload.id}`;
        state.URL.CONTACTS = `https://agent-api.trii.app/api/v1/contacts/${action.payload.id}`;
        state.URL.MEDIA = `https://agent-api.trii.app/api/v1/media/${action.payload.id}`;
        state.URL.MESSAGES = `https://agent-api.trii.app/api/v1/messages/${action.payload.id}`;
        state.URL.CALENDAR = `https://agent-api.trii.app/api/v1/calendar/${action.payload.id}`;
        state.URL.MARKETING = `https://agent-api.trii.app/api/v1/marketing/${action.payload.id}`;
        state.URL.TEMPLATES = `https://agent-api.trii.app/api/v1/conversations/${action.payload}/TemplateMessages`;

        state.status.fetch = 'succeeded';
      }
    );
  },
});
export const { setSpaceInfo, setSpaceFetchStatus, setSpaceUrls } =
  spaceSlice.actions;

const selectSpaceState = (state: RootState) => state.Space;
export const selectSpaceInfo = createSelector(
  selectSpaceState,
  (space) => space.spaceInfo
);

export default spaceSlice.reducer;
