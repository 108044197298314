import {
  Card,
  List,
  ListItem,
  Icon,
  Text,
  Bold,
  Flex,
  Title,
  Metric,
  Divider,
} from "@tremor/react";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress } from "@mui/material";


const PerformanceCard = (item) => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      }}
    >
      <Box
        className="truncate text-center"
        style={{ color: theme.palette.text.primary }}
      >
        <Title style={{ color: theme.palette.text.primary }}>
          {item.title}
        </Title>
        <Metric  className="text-center cursor-pointer text-2xl">
          {item.number}
        </Metric>
      </Box>
      <Divider></Divider>
      <List
        className="mt-4  overflow-y-auto pr-2  min-h-382 position-relative"
        style={{ minHeight: "150px", maxHeight: "150px" }}
      >
        {/* Verificamos si está cargando los datos */}
        {item.isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />  {/* Mostramos el spinner */}
          </Box>
        ) : item.list && item.list.length > 0 ? (
          item.list.map((listItem) => (
            <ListItem style={{ color: theme.palette.text.primary }} key={listItem.name} className="py-1">
              <Flex className="justify-start truncate">
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>{listItem.name}</Bold>
                  </Text>
                </div>
              </Flex>
              <Text>{listItem.quantity}</Text>
            </ListItem>
          ))
        ) : (
          <div className="center-position-absolute" style={{ color: theme.palette.text.primary }}>
            <Text>{t("dashboard.NoDataAvailable")}</Text>
          </div>
        )}
      </List>
    </Card>
  );
};
export default PerformanceCard