import { Box } from "@mui/material";
import {
  CampaignListPanel,
  SidebarFilter,
  ReportViewer
} from "./components";
import { useReport } from "../../context/ReportContext";


const AppBody = () => {
  const { showReport, toggleReport } = useReport();
  return (
    <Box
      display="flex"
      mt={1}
      height={"calc(100% - 10px)"}
      // maxHeight={'95%'}
      width="100%"
    >
      {showReport ?(
        <><SidebarFilter /><ReportViewer /></>
      ):(
      <><SidebarFilter /><CampaignListPanel /></>
      )}
    </Box>
  );
};

export default AppBody;
