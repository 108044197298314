import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Box as MuiBox,
  Typography,
  Button,
  IconButton,
  styled,
  TextareaAutosize,
} from '@mui/material';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { SmsEditor, WhatsappEditor } from './components';
// Redux
import {
  setDraftingTextareaValue,
  setSavedDraftingTextareaValue,
} from 'redux/features/createCampaignSlice/createCampaignSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectCampaignType,
  selectDraftingTextareaValue,
  selectSavedDraftingTextareaValue,
} from 'redux/features/createCampaignSlice/selectors';
import { useSelector } from 'react-redux';
// Types
import { CampaignType } from '@trii/types/dist/Marketing';
import { ManualMessage } from './components/WhatsappEditor/components';
import GrapesEditor from './components/GrapesEditor';

interface PreviewBoxContentProps {
  isEditing: boolean;
  onCancel: () => void;
  onOpenModal: () => void;
  title: string;
}

const PreviewBox = styled(MuiBox)<{ expanded: boolean; fullscreen: boolean }>(
  ({ expanded, fullscreen, theme }) => ({
    height: '100%',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    transition: 'width 0.5s ease-in-out',
    overflow: 'hidden',
    position: fullscreen ? 'absolute' : 'relative',
    left: fullscreen ? 0 : 'auto',
    top: fullscreen ? 0 : 'auto',
    width: fullscreen ? '100%' : expanded ? '60%' : '40%',
    //@ts-ignore
    background: theme.palette.background.default,
    zIndex: fullscreen ? 1 : 'auto',
    border: '1px solid lightgray',
    padding: 10,
  })
);

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
`
);

const HeaderBox = styled(MuiBox)<{ isEditing: boolean }>(({ isEditing }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  visibility: isEditing ? 'visible' : 'hidden',
  opacity: isEditing ? 1 : 0,
  transition: isEditing
    ? 'opacity 0.3s ease-in-out, visibility 0s'
    : 'opacity 0.3s ease-in-out, visibility 0s linear 0.3s',
}));

const ButtonContainer = styled(MuiBox)({
  display: 'flex',
  gap: 1,
});

const PreviewBoxContent: React.FC<PreviewBoxContentProps> = ({
  isEditing,
  onCancel,
  onOpenModal,
  title,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const campaignType = useSelector(selectCampaignType);
  const savedDraftingTextareaValue = useSelector(selectSavedDraftingTextareaValue);

  const handleSave = () => {
    onCancel();
    dispatch(setSavedDraftingTextareaValue());
  };

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  return (
    <PreviewBox expanded={isEditing} fullscreen={isFullscreen}>
      <HeaderBox isEditing={isEditing}>
        <Typography variant="h6">{title}</Typography>
        <ButtonContainer>
          <Button size="small" variant="outlined" onClick={onCancel}>
            {t('global.cancel')}
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {t('global.save')}
          </Button>
          <IconButton color="primary" onClick={toggleFullscreen}>
            <AspectRatioIcon />
          </IconButton>
        </ButtonContainer>
      </HeaderBox>
      {!isEditing && (
        <Textarea
          disabled
          value={savedDraftingTextareaValue}
          minRows={18}
          style={{ resize: 'none' }}
        />
      )}
      {campaignType === CampaignType.SMS && isEditing && <SmsEditor />}
      {campaignType === CampaignType.WHATSAPP && isEditing && <WhatsappEditor />}
      {campaignType === CampaignType.CALL && isEditing && <ManualMessage />}
      {campaignType === CampaignType.EMAIL && isEditing && <GrapesEditor />}
    </PreviewBox>
  );
};

export default PreviewBoxContent;
