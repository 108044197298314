import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
// Types
import {
  CampaignSliceState,
  CreateCampaignData,
  Recipients,
  XlsxRowValue,
} from './types/index';
import { RootState } from 'redux/rootReducer';
import {
  CampaignContactsOrigin,
  CampaignType,
  ICampaign,
} from '@trii/types/dist/Marketing';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Utils
import createCampaignService from './service';
import { initRequestData } from 'redux/functions/initRequestData';
import { v4 as uuidv4 } from 'uuid';
import messagesService from '../messagesSlice/messagesService';
import { UploadMedia } from '../messagesSlice/types/UploadMedia';

const initialState: CampaignSliceState = {
  campaignType: null,
  campaignName: '',
  recipients: {
    contactsOrigin: 1,
  },
  writing: '',
  createdCampaign: null,
  selectedSegmentIds: [],
  selectedListIds: [],
  selecteXlsxFile: null,
  xlsxFileRowValues: [],
  selectedXlsxFileRowValue: null,
  draftingTextareaValue: '',
  savedDraftingTextareaValue: '',
  whatsappTemplateSelectValue: '',
  status: {
    create: 'idle',
    uploadMedia: 'idle',
  },
};

export const createCampaign = createAsyncThunk(
  'CreateCampaign/createCampaign',
  async (data: CreateCampaignData, { dispatch }) => {
    const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    try {
      const response = await createCampaignService.createCampaign(
        URL_MARKETING,
        jwtToken,
        data
      );

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateCampaignRecipients = createAsyncThunk(
  'CreateCampaign/updateCampaignRecipients',
  async (_, { dispatch, getState }) => {
    const { jwtToken, URL_MARKETING, URL_MEDIA } = (
      await dispatch(initRequestData())
    ).payload as InitRequestDataReturn;
    const state = getState() as RootState;
    const createCampaignState = state.CreateCampaign;
    const { contactsOrigin } = createCampaignState.recipients;

    let data: Recipients = {
      contactsOrigin,
    };

    if (contactsOrigin === CampaignContactsOrigin.LIST) {
      data.contactsListsId = createCampaignState.selectedListIds;
    } else if (contactsOrigin === CampaignContactsOrigin.SEGMENT) {
      data.contactsSegmentsId = createCampaignState.selectedSegmentIds;
    } else {
      const file = createCampaignState.selecteXlsxFile;
      const id = uuidv4();
      const formData = new FormData();

      formData.append('file', file);

      const response = await messagesService.uploadMedia(
        jwtToken,
        URL_MEDIA,
        formData,
        file.name,
        id,
        { module: 'marketing', folderType: 'documents' }
      );

      data.contactsFileUrl = response.url;
    }

    try {
      const response = await createCampaignService.updateCampaignRecipients(
        URL_MARKETING,
        jwtToken,
        data,
        createCampaignState.createdCampaign?.id
      );

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const uploadCampaignMedia = createAsyncThunk(
  'CreateCampaign/uploadCampaignMedia',
  async (data: UploadMedia, { dispatch }) => {
    const { jwtToken, URL_MEDIA } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const { file, name, id, folderType } = data;

    const response = await messagesService.uploadMedia(
      jwtToken,
      URL_MEDIA,
      file,
      name,
      id,
      { module: 'marketing', folderType }
    );

    return response;
  }
);

export const createCampaignSlice = createSlice({
  name: 'CreateCampaign',
  initialState,
  reducers: {
    resetCreateCampaignSliceState: () => initialState,
    setCampaignType: (state, action: PayloadAction<CampaignType>) => {
      state.campaignType = action.payload;
    },
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.campaignName = action.payload;
    },
    setRecipients: (state, action) => {
      state.recipients = action.payload;
    },
    setWriting: (state, action) => {
      state.writing = action.payload;
    },
    setRecipientsContactsOrigin: (
      state,
      action: PayloadAction<CampaignContactsOrigin>
    ) => {
      state.recipients.contactsOrigin = action.payload;
    },
    updateRecipients: (state, action: PayloadAction<Partial<Recipients>>) => {
      if (state.recipients) {
        state.recipients = {
          ...state.recipients,
          ...action.payload,
        };
      } else {
        state.recipients = action.payload as Recipients;
      }
    },
    setSelectedListIds: (state, action: PayloadAction<string[]>) => {
      state.selectedListIds = action.payload;
    },
    setSelectedSegmentIds: (state, action: PayloadAction<string[]>) => {
      state.selectedSegmentIds = action.payload;
    },
    toggleSegmentSelecteId: (state, action: PayloadAction<string>) => {
      const index = state.selectedSegmentIds.indexOf(action.payload);

      if (index === -1) {
        state.selectedSegmentIds.push(action.payload);
      } else {
        state.selectedSegmentIds.splice(index, 1);
      }
    },
    toggleListSelecteId: (state, action: PayloadAction<string>) => {
      const index = state.selectedListIds.indexOf(action.payload);

      if (index === -1) {
        state.selectedListIds.push(action.payload);
      } else {
        state.selectedListIds.splice(index, 1);
      }
    },
    setXlsxFile: (state, action: PayloadAction<File>) => {
      state.selecteXlsxFile = action.payload;
    },
    setXlsxFileRowValues: (state, action: PayloadAction<XlsxRowValue[]>) => {
      state.xlsxFileRowValues = action.payload;
    },
    setSelectedXlsxFileRowValue: (state, action: PayloadAction<XlsxRowValue>) => {
      state.selectedXlsxFileRowValue = action.payload;
    },
    setDraftingTextareaValue: (state, action: PayloadAction<string>) => {
      state.draftingTextareaValue = action.payload;
    },
    setSavedDraftingTextareaValue: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.savedDraftingTextareaValue = action.payload;
      } else {
        state.savedDraftingTextareaValue = state.draftingTextareaValue;
      }
    },
    syncDraftingTextareaValue: (state) => {
      state.draftingTextareaValue = state.savedDraftingTextareaValue;
    },
    setWhatsappTemplateSelectValue: (state, action: PayloadAction<string>) => {
      state.whatsappTemplateSelectValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCampaign.pending, (state, action) => {
      state.status.create = 'loading';
    });
    builder.addCase(
      createCampaign.fulfilled,
      (state, action: PayloadAction<ICampaign>) => {
        state.status.create = 'succeeded';
        state.createdCampaign = action.payload;
      }
    );
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.status.create = { error: 'Error' };
    });
    builder.addCase(
      updateCampaignRecipients.fulfilled,
      (state, action: PayloadAction<ICampaign>) => {
        state.status.create = 'succeeded';
        state.createdCampaign = action.payload;
      }
    );
    builder.addCase(uploadCampaignMedia.pending, (state, action) => {
      state.status.uploadMedia = 'loading';
    });
    builder.addCase(uploadCampaignMedia.fulfilled, (state, action) => {
      state.status.uploadMedia = 'idle';
    });
  },
});

export const {
  setCampaignType,
  setCampaignName,
  setRecipients,
  setWriting,
  updateRecipients,
  resetCreateCampaignSliceState,
  toggleListSelecteId,
  toggleSegmentSelecteId,
  setXlsxFile,
  setRecipientsContactsOrigin,
  setXlsxFileRowValues,
  setSelectedXlsxFileRowValue,
  setSelectedListIds,
  setSelectedSegmentIds,
  setDraftingTextareaValue,
  setSavedDraftingTextareaValue,
  syncDraftingTextareaValue,
  setWhatsappTemplateSelectValue,
} = createCampaignSlice.actions;

export default createCampaignSlice.reducer;
