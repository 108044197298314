// GrapesEditor.tsx
import React, { useEffect, useRef } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css'; // Import the default CSS styles

const GrapesEditor: React.FC = () => {
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editorRef.current) {
      const editor = grapesjs.init({
        container: editorRef.current, // Attach GrapesJS to this container
        fromElement: true, // Optional: Use pre-existing HTML inside the container
        height: '450px',
        width: 'auto',

        storageManager: {
          type: 'remote',
          autosave: false, // Disable autosave if you want to trigger saving manually
          autoload: true, // Automatically load the template on init
          stepsBeforeSave: 15, // Save after every change (optional)

          options: {
            // Optional: Define which keys to store (HTML, CSS, components, etc.)
            params: { _someAuthToken: 'your-auth-token' }, // Custom params (like auth)
            contentTypeJson: true, // Use JSON payloads
            headers: {
              Authorization: 'Bearer your-token',
              'Content-Type': 'application/json',
            },
            // API Endpoints
            urlStore: 'https://your-api.com/api/templates', // Save template here
            urlLoad: 'https://your-api.com/api/templates/1', // Load template here
          },
        },

        panels: { defaults: [] }, // Optional: Customize the top panels
        blockManager: {
          appendTo: '#blocks',
          blocks: [
            {
              id: 'section', // id is mandatory
              label: '<b>Section</b>', // You can use HTML/SVG inside labels
              attributes: { class: 'gjs-block-section' },
              content: `<section>
                </section>`,
            },
            {
              id: 'text',
              label: 'Text',
              content: '<div data-gjs-type="text">Insert your text here</div>',
            },
            {
              id: 'image',
              label: 'Image',
              // Select the component once it's dropped
              select: true,
              // You can pass components as a JSON instead of a simple HTML string,
              // in this case we also use a defined component type `image`
              content: { type: 'image' },
              // This triggers `active` event on dropped components and the `image`
              // reacts by opening the AssetManager
              activate: true,
            },
          ],
        },
      });

      // Optional: Add Save button to trigger manual save
      editor.Panels.addButton('options', {
        id: 'save-template',
        className: 'fa fa-save',
        command: () => editor.store(), // Manually trigger store
        attributes: { title: 'Save Template' },
      });

      return () => editor.destroy(); // Cleanup on unmount
    }
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <div
        id="blocks"
        style={{ width: '200px', padding: '10px', background: '#f5f5f5' }}
      />
      <div ref={editorRef} style={{ flex: 1 }} />
    </div>
  );
};

export default GrapesEditor;
