import React from "react"
import {
  Card,
  List,
  ListItem,
  Icon,
  Text,
  Bold,
  Flex,
  Title,
} from "@tremor/react";
import { Box, CircularProgress } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { MessageAck } from "@trii/types/dist/Common/Messages";



const SimpleListCard = (item) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const statusMap = {
    [MessageAck.ACK_QUEUE]: t("statuses.ackQueue", "En cola"),
    [MessageAck.ACK_PENDING]: t("statuses.ackPending", "Pendiente"),
    [MessageAck.ACK_NONE]: t("statuses.ackNone", "Sin reconocimiento"),
    [MessageAck.ACK_ERROR]: t("statuses.ackError", "Error"),
    [MessageAck.ACK_SERVER]: t("statuses.ackServer", "Servidor"),
    [MessageAck.ACK_DEVICE]: t("statuses.ackDevice", "Dispositivo"),
    [MessageAck.ACK_READ]: t("statuses.ackRead", "Leído"),
    [MessageAck.ACK_PLAYED]: t("statuses.ackPlayed", "Reproducido"),
  };


  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        height: "100%"
      }}
    >
      <Title style={{ color: theme.palette.text.primary }}>{item.title}</Title>
      <List className="mt-4  overflow-y-auto pr-2  min-h-382 position-relative" style={{minHeight: "250px", maxHeight: "250px"}}>
        {/* Si está cargando, mostramos el spinner */}
        {item.isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : item.list.length > 0 ? (
          item.list.map((recipients) => (
            <ListItem
              style={{ color: theme.palette.text.primary }}
              key={recipients.address}
              className="py-1"
            >
              <Flex className="justify-start truncate">
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>{recipients.name || recipients.address}</Bold>
                  </Text>
                </div>
              </Flex>
              {/* Mostrar el estado del destinatario */}
              <Text>{statusMap[recipients.status] || t("statuses.unknown", "Desconocido")}</Text>
            </ListItem>
          ))
        ) : (
          // Si no hay datos, mostrar mensajes de "No Data"
          <>
            {item.id === "channels" ? (
              <div className="center-position-absolute" style={{ color: theme.palette.text.primary }}>
                <Text>{t("dashboard.NoChannelsList")}</Text>
              </div>
            ) : (
              <div className="center-position-absolute" style={{ color: theme.palette.text.primary }}>
                <Text>{t("dashboard.NoTagsList")}</Text>
              </div>
            )}
          </>
        )}
      </List>
    </Card>
  );
};

export default SimpleListCard