// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-action-menu-container .prettier-wrapper {
  position: relative;
}

.code-action-menu-container .prettier-wrapper .code-error-tips {
  padding: 5px;
  border-radius: 4px;
  color: #fff;
  background: #222;
  margin-top: 4px;
  position: absolute;
  top: 26px;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailEditor/plugins/CodeActionMenuPlugin/components/PrettierButton/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV","sourcesContent":[".code-action-menu-container .prettier-wrapper {\n  position: relative;\n}\n\n.code-action-menu-container .prettier-wrapper .code-error-tips {\n  padding: 5px;\n  border-radius: 4px;\n  color: #fff;\n  background: #222;\n  margin-top: 4px;\n  position: absolute;\n  top: 26px;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
