// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  z-index: 1;
  outline: none;
}

:root {
  --select-border: #393939;
  --select-focus: #101484;
  --select-arrow: var(--select-border);
}

.select {
  min-width: 160px;
  max-width: 290px;
  border: 1px solid var(--select-border);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.4;
  background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailEditor/ui/Select.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,qBAAqB;EACrB,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,oBAAoB;;EAEpB,UAAU;EACV,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,sCAAsC;EACtC,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gEAAgE;AAClE","sourcesContent":["select {\n  appearance: none;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  background-color: transparent;\n  border: none;\n  padding: 0 1em 0 0;\n  margin: 0;\n  font-family: inherit;\n  font-size: inherit;\n  cursor: inherit;\n  line-height: inherit;\n\n  z-index: 1;\n  outline: none;\n}\n\n:root {\n  --select-border: #393939;\n  --select-focus: #101484;\n  --select-arrow: var(--select-border);\n}\n\n.select {\n  min-width: 160px;\n  max-width: 290px;\n  border: 1px solid var(--select-border);\n  border-radius: 0.25em;\n  padding: 0.25em 0.5em;\n  font-size: 1rem;\n  cursor: pointer;\n  line-height: 1.4;\n  background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
