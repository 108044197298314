import { Box, ListItem, useTheme } from "@mui/material";
import { Card, DonutChart, List, Title } from "@tremor/react";
import { useTranslation } from "react-i18next";


const valueFormatter = (number) => `${number}%`;
const GrapichDestiny = ( {data} ) => {
  const theme = useTheme();
  const {t} = useTranslation()
  const deliveriesData = [
    { type: "Sin rebotes", sales: data.withoutBounces || 0 },
    { type: "Repetidos/Excluidos", sales: data.repeatedExcluded || 0 },
    { type: "Pendientes", sales: data.pendings || 0 },
    { type: "Con rebotes", sales: data.bounces || 0 }
  ];

  return (
    <Card
      className="p-4"
      style={{
        //@ts-ignore
        backgroundColor: theme.palette.background.panel,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
        height: "100%",
      }}
    >
      <Title style={{ color: theme.palette.text.primary }} >{t("dashboard.deliveries")}</Title>
      <Box className="p-4 flex items-center space-x-6">
        <DonutChart
          className="mt-6"
          data={deliveriesData}
          category="sales"
          index="type"
          valueFormatter={valueFormatter}
          colors={["slate", "violet", "indigo", "rose"]}
        />
        <List className="w-2/3">
          {deliveriesData.map((locationData) => (
            <ListItem style={{ color: theme.palette.text.primary }} key={locationData.type} className="space-x-2">
              <div className="flex items-center space-x-2 truncate">
                <span
                  style={{
                    width: "1em",
                    height: "1em",
                    backgroundColor:
                      locationData.type === "Sin rebotes"
                        ? "#64748b"
                        : locationData.type === "Repetidos/Excluidos"
                        ? "violet"
                        : locationData.type === "Pendientes"
                        ? "indigo"
                        : locationData.type === "Con rebotes"
                        ? "#f43f5e"
                        : "",
                  }}
                />
                <span className="truncate">{locationData.type === "Sin rebotes"
                        ? t("dashboard.noBounces")
                        : locationData.type === "Repetidos/Excluidos"
                        ? t("dashboard.repeatedExcluded")
                        : locationData.type === "Pendientes"
                        ? t("dashboard.pending")
                        : locationData.type === "Con rebotes"
                        ? t("dashboard.withBounces")
                        : ""}</span>
              </div>
              <span>{locationData.sales} %</span>
            </ListItem>
          ))}
        </List>
      </Box>
    </Card>
  );
};

export default GrapichDestiny;
