import { Box as MuiBox, Button as MuiButton, styled } from '@mui/material';
import { PreviewBoxContent } from './components';
import { useTranslation } from 'react-i18next';
import { syncDraftingTextareaValue } from 'redux/features/createCampaignSlice/createCampaignSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

interface MainContentAreaProps {
  isEditing: boolean;
  setIsEditing: (expanded: boolean) => void;
  setIsModalOpen: (open: boolean) => void;
  title: string;
}

const MainContent = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  borderRadius: 2,
  gap: 20,
  transition: 'all 0.3s ease-in-out',
});

const MainContentArea: React.FC<MainContentAreaProps> = ({
  isEditing,
  setIsEditing,
  setIsModalOpen,
  title,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleExpand = () => {
    dispatch(syncDraftingTextareaValue());
    setIsEditing(true);
  };

  const handleCancel = () => setIsEditing(false);

  return (
    <MainContent>
      <PreviewBoxContent
        isEditing={isEditing}
        onCancel={handleCancel}
        onOpenModal={() => setIsModalOpen(true)}
        title={title}
      />
      {!isEditing && (
        <MuiBox sx={{ alignSelf: 'flex-end' }}>
          <MuiButton variant="contained" color="primary" onClick={handleExpand}>
            {t('global.edit')}
          </MuiButton>
        </MuiBox>
      )}
    </MainContent>
  );
};

export default MainContentArea;
