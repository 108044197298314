import { Box } from '@mui/material';
import {
  AppBody,
} from './components';
import { ReportProvider } from './context/ReportContext';


const Reports = () => {
  return (
    <ReportProvider>
      <Box
        display="flex"
        width="100%"
        height={"100%"}
        flexDirection={"column"}
        className={"marketing-paddingmd"}
      >
        <AppBody />
      </Box>
  </ReportProvider>
  
  );
};

export default Reports;
