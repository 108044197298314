import { useContext } from 'react';
import { useState, useEffect } from 'react';
//import {useDispatch, useSelector  } from 'react-redux';
import 
{ TableCell, 
  Button, 
 
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import { ContactBusinessTableContext } from 'features/Views/MarketingView/context/ContactBusinessTableContext';
import CampaignStatusTag from './components/CampaignStatusTag';
import { useReport } from 'features/Views/Reports/context/ReportContext';



const Cells = ({ campaign }) => {
  const [anchorEl, setAnchorEL] = useState(null);
  const open = Boolean(anchorEl);
  const {toggleReport, setselectedCampaignId} = useReport();


  const handleClick = (id) => {
    setselectedCampaignId(id);
    toggleReport();
  }

  const handleClose = () => {
    setAnchorEL(null);
  }

  const columnDialog = [
    {
      title: "Estado",
      visible: true,
      value: <CampaignStatusTag status={campaign.status} />
    },
    {
      title: "Nombre",
      visible: true,
      value: campaign.title

    },
    {
      title: "Destinatarios",
      visible: true,
      value: campaign.countersRecipients

    },
    {
      title: "Entregados",
      visible: true,
      value: campaign.countersContacts

    },
    {
      title: "Aperturas(%)",
      visible: true,
      value: campaign.countersOpenings

    },
    {
      title: "Clicks",
      visible: true,
      value: campaign.countersClicks

    },
    {
      title: "Fecha de envío",
      visible: true,
      value: campaign.createdAt ? new Date(campaign.createdAt).toLocaleDateString() : "Fecha no disponible"


    },
    {
      title: "Acciones",
      visible: true,
      value: (
        <>
          <Button variant='contained' size="small" onClick={() => handleClick(campaign.id)}>Ver informe</Button>
          
        </>
      )

    },
  ]

  // const columns = useSelector(selectContactFields);
  return (
    <>
      {columnDialog.map(
        (column, i) =>
          column.visible && (
            <TableCell key={i} sx={{ padding: '0 24px' }}>
              <span
                // onClick={() => handleContactClick(contact.id)}
                style={{ userSelect: 'none' }}
              >
                {column.value}
              </span>
            </TableCell>
          )
      )}
      <TableCell sx={{ padding: '0 24px' }}>

      </TableCell>
    </>
  );
};

export default Cells;
