// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-action-menu-container {
  height: 35.8px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: row;
  user-select: none;
}

.code-action-menu-container .code-highlight-language {
  margin-right: 4px;
}

.code-action-menu-container button.menu-item {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
  background: none;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}

.code-action-menu-container button.menu-item i.format {
  height: 16px;
  width: 16px;
  opacity: 0.6;
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  background-size: contain;
}

.code-action-menu-container button.menu-item:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 0.9;
}

.code-action-menu-container button.menu-item:active {
  background-color: rgba(223, 232, 250);
  border: 1px solid rgba(0, 0, 0, 0.45);
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailEditor/plugins/CodeActionMenuPlugin/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,qCAAqC;EACrC,qCAAqC;AACvC","sourcesContent":[".code-action-menu-container {\n  height: 35.8px;\n  font-size: 10px;\n  color: rgba(0, 0, 0, 0.5);\n  position: absolute;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  user-select: none;\n}\n\n.code-action-menu-container .code-highlight-language {\n  margin-right: 4px;\n}\n\n.code-action-menu-container button.menu-item {\n  border: 1px solid transparent;\n  border-radius: 4px;\n  padding: 4px;\n  background: none;\n  cursor: pointer;\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n  color: rgba(0, 0, 0, 0.5);\n  text-transform: uppercase;\n}\n\n.code-action-menu-container button.menu-item i.format {\n  height: 16px;\n  width: 16px;\n  opacity: 0.6;\n  display: flex;\n  color: rgba(0, 0, 0, 0.5);\n  background-size: contain;\n}\n\n.code-action-menu-container button.menu-item:hover {\n  border: 1px solid rgba(0, 0, 0, 0.3);\n  opacity: 0.9;\n}\n\n.code-action-menu-container button.menu-item:active {\n  background-color: rgba(223, 232, 250);\n  border: 1px solid rgba(0, 0, 0, 0.45);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
