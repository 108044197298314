import { useState } from 'react';
// Components/ui
import { SelectChangeEvent, Box as MuiBox, styled } from '@mui/material';
import { MainContentArea, ModalDialog, SelectDropdown } from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectCampaignType } from 'redux/features/createCampaignSlice/selectors';
import { CampaignType } from '@trii/types/dist/Marketing';
import { useTranslation } from 'react-i18next';

const Container = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  width: '100%',
  minHeight: 500,
  padding: 10,
});

const WritingStep = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Manage expanded state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const campaignType = useSelector(selectCampaignType);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value as string);
  };

  const title =
    campaignType === CampaignType.SMS
      ? t('campaigns.smsEdition')
      : campaignType === CampaignType.WHATSAPP
      ? t('campaigns.whatsAppEdition')
      : campaignType === CampaignType.CALL
      ? t('campaigns.rcsEdition')
      : 'Title';

  return (
    <Container>
      <SelectDropdown value={selectedOption} onChange={handleChange} />
      <MainContentArea
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        setIsModalOpen={setIsModalOpen}
        title={title}
      />
      {/* <ModalDialog
        title={title}
        isOpen={isModalOpen}
        setIsEditing={setIsEditing}
        onClose={() => setIsModalOpen(false)}
      /> */}
    </Container>
  );
};

export default WritingStep;
