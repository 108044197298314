import { RootState } from 'redux/rootReducer';

// Selectors
const campaignState = (state: RootState) => state.CreateCampaign;

export const selectCampaignType = (state: RootState) =>
  campaignState(state).campaignType;

export const selectCampaignName = (state: RootState) =>
  campaignState(state).campaignName;

export const selectRecipients = (state: RootState) =>
  campaignState(state).recipients;

export const selectWriting = (state: RootState) => campaignState(state).writing;

export const selectCreateCampaignStatus = (state: RootState) =>
  campaignState(state).status.create;

export const selectSelectedSegmentIds = (state: RootState) =>
  campaignState(state).selectedSegmentIds;

export const selectSelectedListIds = (state: RootState) =>
  campaignState(state).selectedListIds;

export const selectXlsxFile = (state: RootState) =>
  campaignState(state).selecteXlsxFile;

export const selectXlsxFileRowValues = (state: RootState) =>
  campaignState(state).xlsxFileRowValues;

export const selectSelectedXlsxFileRowValue = (state: RootState) =>
  campaignState(state).selectedXlsxFileRowValue;

export const selectDraftingTextareaValue = (state: RootState) =>
  campaignState(state).draftingTextareaValue;

export const selectSavedDraftingTextareaValue = (state: RootState) =>
  campaignState(state).savedDraftingTextareaValue;

export const selectUploadMediaStatus = (state: RootState) =>
  campaignState(state).status.uploadMedia;

export const selectWhatsappTemplateSelectValue = (state: RootState) =>
  campaignState(state).whatsappTemplateSelectValue;
