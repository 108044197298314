import { useContext } from 'react';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Components/ui
import { TableCell, Checkbox, TableRow, styled } from '@mui/material';
import { Cells } from './components';
import { ICampaign } from '@trii/types/dist/Campaigns';
// Types
// import type { IContactWithCheckbox } from 'redux/features/contactsSlice/types/IContactWithCheckBox';
// import type { IBusinessWithCheckbox } from 'redux/features/contactsSlice/types/IBusinessWithCheckBox';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  height: 35,
}));

// interface Props {
//   contact: IContactWithCheckbox | IBusinessWithCheckbox;
// }

// Definimos las props que Contact va a recibir
interface Props {
  campaign: ICampaign;
}

const Campaign = ({ campaign }: Props) => { 

// const Contact = () => {
//   // const { handleItemCheck } = useContext(ContactBusinessTableContext);

  return (
    <StyledTableRow>
      {/* <TableCell sx={{ padding: '0 16px' }}>
        <Checkbox
          // checked={campaign.checked}Lógica para el checkbox
          // onChange={() => handleItemCheck(campaign.id)}
          sx={{ marginRight: 1 }}
        />
      </TableCell> */}
      <Cells campaign={campaign} />
      <TableCell sx={{ padding: '0 16px' }} />
    </StyledTableRow>
  );
};

export default Campaign;
