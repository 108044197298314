// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { setDraftingTextareaValue } from 'redux/features/createCampaignSlice/createCampaignSlice';
import { selectDraftingTextareaValue } from 'redux/features/createCampaignSlice/selectors';
// Components/ui
import { styled, TextareaAutosize } from '@mui/material';

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
  `
);

const SmsEditor = () => {
  const dispatch = useAppDispatch();

  const textAreaValue = useSelector(selectDraftingTextareaValue);

  return (
    <Textarea
      minRows={16}
      maxRows={16}
      value={textAreaValue}
      onChange={(event) => dispatch(setDraftingTextareaValue(event.target.value))}
      sx={{ resize: 'none' }}
    />
  );
};

export default SmsEditor;
