import React, { useState } from 'react';
// Components/ui
import {
  Modal,
  Box as MuiBox,
  IconButton as MuiIconButton,
  styled,
} from '@mui/material';
// Icons
import { Close } from '@mui/icons-material';

const ThumbnailImage = styled('img')({
  cursor: 'pointer',
  marginLeft: '1rem',
  width: '10%',
  height: '10%',
});

const ModalBox = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme.palette.background.paper,
  boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
  padding: theme.spacing(4),
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

const CloseButton = styled(MuiIconButton)({
  position: 'absolute',
  top: '10px',
  right: '10px',
});

const ModalImage = styled('img')({
  width: '50%',
  height: '50%',
});

const ImageModal = ({ imageUrl, altText }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <ThumbnailImage src={imageUrl} alt={altText} onClick={handleModalOpen} />
      <Modal open={modalOpen} onClose={handleModalClose}>
        <ModalBox>
          <ModalImage src={imageUrl} alt={altText} id="img01" />
          <CloseButton aria-label="close" onClick={handleModalClose}>
            <Close />
          </CloseButton>
        </ModalBox>
      </Modal>
    </>
  );
};

export default ImageModal;
