import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Utils
import segmentsService from './service';
import { initRequestData } from 'redux/functions/initRequestData';
// Types
import { IInitialState } from './types';
import { ISegment } from '@trii/types/dist/Contacts';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';

const initialState: IInitialState = {
  status: {
    fetch: 'idle',
  },
  data: [],
};

export const fetchSegments = createAsyncThunk<ISegment[]>(
  'segments/fetchSegments',
  async (_, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await segmentsService.fetchSegments(URL_CONTACTS, jwtToken);

    return response;
  }
);

export const segmentsSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    resetSegmentsSliceState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSegments.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchSegments.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        state.data = action.payload;
      });
  },
});

// Actions
export const { resetSegmentsSliceState } = segmentsSlice.actions;

export default segmentsSlice.reducer;
