// Components/ui
import { Box, FormControl } from '@mui/material';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectRecipients,
  selectSelectedListIds,
  selectSelectedSegmentIds,
} from 'redux/features/createCampaignSlice/selectors';
import {
  toggleListSelecteId,
  toggleSegmentSelecteId,
  setSelectedListIds,
  setSelectedSegmentIds,
} from 'redux/features/createCampaignSlice/createCampaignSlice';
import { useSelector } from 'react-redux';
// Types
import { Origins, RecipientList, XlsxOrigin } from './componets';
import { CampaignContactsOrigin } from '@trii/types/dist/Marketing';

import { useEffect } from 'react';
import {
  selectSegments,
  selectSegmentsStatus,
} from 'redux/features/segmentsSlice/selectors';
import { fetchSegments } from 'redux/features/segmentsSlice/segmentsSlice';
import { selectLists, selectListsStatus } from 'redux/features/listsSlice/selectors';
import { fetchLists } from 'redux/features/listsSlice/listsSlice';

const RecipientsStep = () => {
  const dispatch = useAppDispatch();

  const recipients = useSelector(selectRecipients);
  const lists = useSelector(selectLists);
  const listsFetchStatus = useSelector(selectListsStatus);
  const selectedListsIds = useSelector(selectSelectedListIds);
  const segments = useSelector(selectSegments);
  const segmentsFetchStatus = useSelector(selectSegmentsStatus);
  const selectedSegmentsIds = useSelector(selectSelectedSegmentIds);

  const isFetchingLists = listsFetchStatus === 'loading';
  const isFetchingSegments = segmentsFetchStatus === 'loading';

  useEffect(() => {
    if (
      recipients.contactsOrigin === CampaignContactsOrigin.SEGMENT &&
      segmentsFetchStatus === 'idle'
    ) {
      dispatch(fetchSegments());
    }

    if (
      recipients.contactsOrigin === CampaignContactsOrigin.LIST &&
      listsFetchStatus === 'idle'
    ) {
      dispatch(fetchLists());
    }
  }, [recipients]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormControl component="fieldset">
        <Origins contactsOrigin={recipients.contactsOrigin} />
      </FormControl>
      {recipients?.contactsOrigin === CampaignContactsOrigin.LIST && (
        <RecipientList
          data={lists}
          checkedItems={selectedListsIds}
          handleToggle={(id) => dispatch(toggleListSelecteId(id))}
          loading={listsFetchStatus === 'loading' && isFetchingLists}
          onUnmount={() => dispatch(setSelectedListIds([]))}
        />
      )}
      {recipients?.contactsOrigin === CampaignContactsOrigin.SEGMENT && (
        <RecipientList
          data={segments}
          checkedItems={selectedSegmentsIds}
          handleToggle={(id) => dispatch(toggleSegmentSelecteId(id))}
          loading={listsFetchStatus === 'loading' && isFetchingSegments}
          onUnmount={() => dispatch(setSelectedSegmentIds([]))}
        />
      )}
      {recipients?.contactsOrigin === CampaignContactsOrigin.XLSX && <XlsxOrigin />}
    </Box>
  );
};

export default RecipientsStep;
