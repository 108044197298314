import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Types
import { NodeMsgTextMode } from '@trii/types/dist/Conversations/Flows/Nodes';
// Components/ui
import { Box as MuiBox, Typography as MuiTypography, styled } from '@mui/material';
import { ManualMessage, TemplateMessage } from './components';
import { TemplatesProvider } from 'context/Templates/TemplatesProvider';

const Container = styled(MuiBox)({
  width: '100%',
  marginBottom: '10px',
});

const HeaderBox = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '100%',
});

const StyledTypography = styled(MuiTypography)<{ active: boolean }>(
  ({ theme, active }) => ({
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    borderBottom: `2px solid ${
      active ? theme.palette.primary.light : 'rgba(0, 0, 0, 0.1)'
    }`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.text.disabled,
  })
);

const WhatsappEditor = () => {
  const { t } = useTranslation();

  const [showTemplateMessage, setShowTemplateMessage] = useState<boolean>(false);
  const [showManualMessage, setShowManualMessage] = useState<boolean>(true);

  const handleMode = (mode: number) => {
    setShowTemplateMessage(mode === NodeMsgTextMode.TEMPLATE);
    setShowManualMessage(mode === NodeMsgTextMode.MANUAL);
  };

  return (
    <Container>
      <HeaderBox>
        <StyledTypography
          variant="body1"
          active={showTemplateMessage}
          onClick={() => handleMode(NodeMsgTextMode.TEMPLATE)}
        >
          {t('marketingView.createDiffusionView.templateMessage')}
        </StyledTypography>
        <StyledTypography
          variant="body1"
          active={showManualMessage}
          onClick={() => handleMode(NodeMsgTextMode.MANUAL)}
        >
          {t('marketingView.createDiffusionView.manualMessage')}
        </StyledTypography>
      </HeaderBox>
      {showManualMessage && <ManualMessage />}
      {showTemplateMessage && (
        <TemplatesProvider>
          <TemplateMessage />
        </TemplatesProvider>
      )}
    </Container>
  );
};

export default WhatsappEditor;
