import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/features/store';

export const selectTemplatesSlice = (state: RootState) => state.Templates;
export const selectTemplates = createSelector(
  (state: RootState) => state.Templates.templates,
  (templates) => templates
);
export const selectTemplateById = (id: string) =>
  createSelector(selectTemplates, (templates) =>
    templates.find((template) => template.id === id)
  );
export const selectTemplatesFetchStatus = createSelector(
  (state: RootState) => state.Templates.status.fetch,
  (fetchStatus) => fetchStatus
);
