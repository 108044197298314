// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // eslint-disable-line
import { Route, Routes as ReactRoutes, useNavigate } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import { Dashboard } from 'features/Views/Dashboard';
import SystemMVC from 'features/Views/SystemMVC';
import ScheduleView from './features/Views/Schedules/ScheduleContainer';
import MarketingView from 'features/Views/MarketingView';
import { useEffect } from 'react';
import NewCampaign from 'features/Views/NewCampaign';
import Reports from 'features/Views/Reports/Reports';
import Options from 'features/Views/Options';
import ScheduleContainer from "./features/Views/Schedules/ScheduleContainer"
import SchedulesEditContainer from "features/Views/SchedulesEdit/SchedulesEditContainer"
import './tailwind.css';

const Routes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if current path is /a/marketing and redirect to /a/marketing/marketing
    if (window.location.pathname === '/a/marketing')
      navigate('/a/marketing/Marketing');
  }, [navigate]);

  return (
    <MainLayout>
      <ReactRoutes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/a/marketing/dashboard" element={<Dashboard />} />
        <Route
          caseSensitive
          path="/a/marketing/Marketing"
          element={<MarketingView />}
        />
        <Route path="/a/marketing/reports" element={<Reports/>} />
        <Route caseSensitive path="/a/marketing/System" element={<SystemMVC />} />
        <Route path="/a/marketing/System/schedule" element={<ScheduleView />} />
        <Route caseSensitive path="/a/marketing/System/schedule/:id" element={<SchedulesEditContainer />} />
        <Route path="/a/marketing/newCampaign" element={<NewCampaign />} />
        <Route caseSensitive path="/a/marketing/System/options" element={<Options />} />
        <Route path="*" element={<Dashboard />} />
      </ReactRoutes>
    </MainLayout>
  );
};

export default Routes;
