import { createContext } from 'react';
// Types
import { TemplatesState } from './types/TemplatesState';
import { EditionType } from './types/EditionType';
import { ChannelList, TemplatesList } from './types/Lists';
import { FocusType } from './types/FocusType';
import { TemplateVar, TemplateVarButton } from '@trii/types/dist/Common/Messages';

export const TemplatesContext = createContext<TemplatesState>({
  editionType: null as EditionType,
  setEditionType: (type: EditionType) => {},
  focusType: null as FocusType,
  isEditing: false,
  setIsEditing: () => {},
  setFocusType: (type: FocusType) => {},
  updateChannel: () => {},
  updateTemplate: (templateId: string, updatedData: Partial<TemplatesList>) => {},
  channelList: [] as ChannelList[],
  setChannelList: (channels: ChannelList[]) => {},
  channel: null as ChannelList | null,
  setChannel: (channel: ChannelList | null) => {},
  templateList: [] as TemplatesList[],
  setTemplateList: (templates: TemplatesList[]) => {},
  template: null as TemplatesList | null,
  setTemplate: (template: TemplatesList | null) => {},
  selectedChannel: null as ChannelList | null,
  setSelectedChannel: (channel: ChannelList | null) => {},
  selectedTemplate: null as TemplatesList | null,
  setSelectedTemplate: (template: TemplatesList | null) => {},
  // Template
  localBodyVariables: [],
  setLocalBodyVariables: (variables: TemplateVar[]) => {},
  localHeaderVariables: [],
  setLocalHeaderVariables: (variables: TemplateVar[]) => {},
  localButtons: [],
  setLocalButtons: (buttons: TemplateVarButton[]) => {},
});
