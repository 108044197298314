import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Componets/ui
import { Box, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
import { TextFormat, ImageFormat, FileFormat } from './components';
// Utils
import formatList from './utils/formatList';
// Types
import { NodeMsgTextMessageType } from '@trii/types/dist/Conversations/Flows/Nodes';

const ManualMessage = () => {
  const { t } = useTranslation();

  const [type, setType] = useState<number>(NodeMsgTextMessageType.TEXT);

  const handleChangeFormat = (value: number) => {
    setType(value);
  };

  return (
    <Box marginTop={3}>
      <FormControl fullWidth size="small">
        <InputLabel id="format" shrink={true}>
          {t('global.format')}
        </InputLabel>
        <Select
          labelId="format"
          id="demo-simple-select"
          value={type}
          label={t('global.format')}
          onChange={(e) => handleChangeFormat(e.target.value as number)}
          sx={{
            '& .MuiSvgIcon-root': {
              color: 'text.disabled',
            },
          }}
        >
          {formatList.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {t(item.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box marginTop={2} position="relative">
        {type === NodeMsgTextMessageType.TEXT && <TextFormat />}
        {type === NodeMsgTextMessageType.IMAGE && (
          <ImageFormat
            title={t('campaigns.selectImage')}
            chargeLabel={t('global.charge')}
            fieldLabel={t('campaigns.imageText')}
            type="image"
          />
        )}
        {type === NodeMsgTextMessageType.VIDEO && (
          <ImageFormat
            title={t('campaigns.selectVideo')}
            chargeLabel={t('global.charge')}
            fieldLabel={t('campaigns.videoText')}
            type="video"
          />
        )}
        {type === NodeMsgTextMessageType.FILE && <FileFormat />}
      </Box>
    </Box>
  );
};

export default ManualMessage;
