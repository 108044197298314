import { useContext } from 'react';
import { useState, useEffect } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchResumeCampaign, fetchPauseCampaign, fetchCancelCampaign, fetchDeleteCampaign, fetchCloneCampaign} from 'redux/features/campaignSlice/campaignSlice';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useSelector } from 'react-redux';
// import { selectContactFields } from 'redux/features/contactsSlice/contactsSlice';
// Components/ui
import 
{ TableCell, 
  Button, 
  Menu, 
  MenuItem, 
  IconButton 
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';

// Components
import { ContactBusinessTableContext } from 'features/Views/MarketingView/context/ContactBusinessTableContext';
import CampaignStatusTag from './components/CampaignStatusTag';

// Types
// import type { IContactWithCheckbox } from 'redux/features/contactsSlice/types/IContactWithCheckBox';
// import type { IBusinessWithCheckbox } from 'redux/features/contactsSlice/types/IBusinessWithCheckBox';

// type Props = {
//   contact: IContactWithCheckbox | IBusinessWithCheckbox;
// };


const Cells = ({ campaign }) => {
  const [anchorEl, setAnchorEL] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const isPaused = campaign.paused;

  const handleClick = (event) => {
    setAnchorEL(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEL(null);
  }

  const handlePauseResume = () => {
    if (isPaused) {
      dispatch(fetchResumeCampaign(campaign.id));
    } else {
      dispatch(fetchPauseCampaign(campaign.id));
    }
    handleClose();
  };

  const handleCancelSend = () => {
    dispatch(fetchCancelCampaign(campaign.id)).then((response) => {
      console.log('Cancelación completada para campaña:', campaign.id);
    }).catch((error) => {
      console.error('Error al cancelar la campaña:', error);
    });
    handleClose();
  };
  
  const handleDeleteCampaign = () => {
    dispatch(fetchDeleteCampaign(campaign.id))
      .then((response) => {
        console.log('Eliminación completada para campaña:', campaign.id);
      })
      .catch((error) => {
        console.error('Error al eliminar la campaña:', error.response?.data || error.message);
      });
    handleClose();
  };

  const handleCloneCampaign = () => {
    dispatch(fetchCloneCampaign(campaign.id))
      .then((response) => {
        console.log('Clonación completada para campaña:', campaign.id);
      })
      .catch((error) => {
        console.error('Error al clonar la campaña:', error.response?.data || error.message);
      });
    handleClose();
  };

  const columnDialog = [
    {
      title: "Estado",
      visible: true,
      value:(
        campaign.status === 3 && campaign.paused 
          ? <span className="bg-orange-200 text-orange-800 inline-block px-3 py-1 rounded-full text-sm font-semibold">Pausada</span>
          : <CampaignStatusTag status={campaign.status} />
      )
    },
    {
      title: "Nombre",
      visible: true,
      value: campaign.title

    },
    {
      title: "Destinatarios",
      visible: true,
      value: campaign.countersRecipients

    },
    {
      title: "Entregados",
      visible: true,
      value: campaign.countersContacts

    },
    {
      title: "Aperturas(%)",
      visible: true,
      value: campaign.countersOpenings

    },
    {
      title: "Clicks",
      visible: true,
      value: campaign.countersClicks

    },
    {
      title: "Fecha de envío",
      visible: true,
      value: campaign.createdAt ? new Date(campaign.createdAt).toLocaleDateString() : "Fecha no disponible"


    },
    {
      title: "Acciones",
      visible: true,
      value: (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '30ch',
              },
            }}
          >
            <MenuItem onClick={handlePauseResume}>
              {isPaused ? 'Reanudar' : 'Pausar'}
            </MenuItem>
            {campaign.status === 20 && (
              <MenuItem onClick={handleCancelSend}>Cancelar envío</MenuItem>
            )}
            <MenuItem onClick={handleClose}>Editar</MenuItem>
            <MenuItem onClick={handleCloneCampaign}>Duplicar</MenuItem>
            <MenuItem onClick={handleDeleteCampaign}>Eliminar</MenuItem>
          </Menu>
        </>
      )

    },
  ]

  // const columns = useSelector(selectContactFields);
  return (
    <>
      {columnDialog.map(
        (column, i) =>
          column.visible && (
            <TableCell key={i} sx={{ padding: '0 24px' }}>
              <span
                // onClick={() => handleContactClick(contact.id)}
                style={{ userSelect: 'none' }}
              >
                {column.value}
              </span>
            </TableCell>
          )
      )}
      <TableCell sx={{ padding: '0 24px' }}>

      </TableCell>
    </>
  );
};

export default Cells;
