import { Box, Button, ToggleButtonGroup, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {  fetchCampaigns, selectFilterStatus, setFilterType, selectFilterType, selectFilterRange, selectFilterToDate, selectFilterFromDate} from 'redux/features/campaignSlice/campaignSlice';
import SmsIcon from '@mui/icons-material/Sms';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch, useSelector } from 'react-redux';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: "4px",
    },
    '&:first-of-type': {
      borderRadius: "4px",
    },
  },
}));

const FilterButtons = () => {
  const currentFilterStatus = useSelector(selectFilterStatus);
  const currentFilterType = useSelector(selectFilterType);
  const currentFilterRange =useSelector(selectFilterRange)
  const currentFilterFromDate =useSelector(selectFilterFromDate);
  const currentFilterToDate =useSelector(selectFilterToDate);
  const theme = useTheme()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const handleFilterClick = (type: number) => {
    if(type==currentFilterType){
      dispatch(setFilterType(null));
      dispatch(fetchCampaigns({ status: currentFilterStatus, type: null, range: currentFilterRange, fromDate: currentFilterFromDate, toDate:currentFilterToDate}));
    } else{
      dispatch(setFilterType(type));
      dispatch(fetchCampaigns({ status: currentFilterStatus, type, range: currentFilterRange, fromDate: currentFilterFromDate, toDate:currentFilterToDate}));
    }
    
};

  // const handleFilterClick = (filterStatus: number) => {
  //   dispatch(setFilterStatus(filterStatus));
  //   dispatch(fetchCampaigns(filterStatus));  
  // };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}
        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick(4)}
      >
        <WhatsAppIcon/> {t("settingsView.whatsApp")}
      </Button>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}

        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick(1)}
      >
          <SmsIcon/> {t("settingsView.sms")}
      </Button>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}

        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick(2)}
      >
        <EmailIcon/> {t("settingsView.email")}

      </Button>
    </Box>
  );
}

export default FilterButtons