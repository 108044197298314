import { Box, Typography } from '@mui/material'
import React from 'react'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button'

const Schedules = () => {
    const { t } = useTranslation();

    return (
        <Button>
            <NavLink to="/a/marketing/System/schedule" >
                <Box display={'flex'} flexDirection={'column'} justifyContent="center" alignItems={"center"}
                    padding={2} sx={{ color: theme => theme.palette.text.primary }}
                >
                    <Box marginBottom={4}>
                        <QueryBuilderIcon sx={{ fontSize: "2.5rem" }} />
                    </Box>
                    <Typography variant={'body2'}>{t("settingsView.schedules")}</Typography>
                </Box>
            </NavLink>
        </Button>
    )
}

export default Schedules