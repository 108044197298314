import { useContext } from 'react';

import { Box } from '@mui/material';
import { FilterByState, FilterByType, SelectDate } from './components';
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';

const AdvancedFilter = () => {
  // const { fieldSelect, initialConditionSelect, valueField, addFilter } = useContext(
  //   ContactBusinessTableContext
  // );

  return (
    <Box sx={{ maxHeight: 'calc(100vh - 330px)', overflow: 'auto' }}>
      {/* Filtros */}
      <FilterByState/>
      <FilterByType/>
      <SelectDate/>


    </Box>
  );
};

export default AdvancedFilter;
FilterByType