import { useEffect, useMemo } from 'react';
import Router from './router';
// Types
import RootProps from 'types/RootProps';
// Context
import SocketProvider from './context/WebSocketProvider/SocketProvider';
// Theme
import { createTheme, ThemeProvider } from '@mui/material';
// Redux
import { getUser, selectUser, setUser } from './redux/features/userSlice/userSlice';
import { useSelector } from 'react-redux';
import { selectSession } from './redux/features/sessionSlice/sessionSlice';
import {
  fetchSpaceInfo,
  selectSpaceInfo,
  setSpaceInfo,
  setSpaceFetchStatus,
  setSpaceUrls,
} from './redux/features/spaceSlice/spaceSlice';
import {
  selectedTheme,
  toggleTheme,
  selectThemeToggleStatus,
} from './redux/features/themeSlice';
// Components/ui
import { PageLoading } from './components';
// Hooks
import { useAppDispatch } from 'hooks/useAppDispatch';
import './App.css';
import { UserInfoProvider } from 'context/UserInfoProvider/UserInfoProvider';
import MarketingContextProvider from 'features/Views/MarketingView/context/MarketingContext';
import MuiComponentsConfig from 'style/MuiComponentesConfig';

interface Props {
  rootProps: RootProps;
}

const App = ({ rootProps }: Props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const space = useSelector(selectSpaceInfo);
  const themeRedux = useSelector(selectedTheme);
  const theme = useMemo(() => MuiComponentsConfig(themeRedux), [themeRedux]);
  const themeToggleStatus = useSelector(selectThemeToggleStatus);

  useEffect(() => {
    if (rootProps?.spaceInfo && rootProps?.userInfo) {
      const { spaceInfo, userInfo } = rootProps;
      dispatch(setSpaceInfo(spaceInfo));
      dispatch(setSpaceFetchStatus('succeeded'));
      dispatch(setSpaceUrls(spaceInfo.id));
      dispatch(setUser(userInfo));
    } else {
      dispatch(fetchSpaceInfo());
      dispatch(getUser());
    }
  }, [dispatch]);

  useEffect(() => {
    if (user !== null) {
      dispatch(toggleTheme(user.theme));
    }
  }, [user, dispatch]);

  return (
    <SocketProvider>
      <UserInfoProvider>
        <ThemeProvider theme={theme}>
          {user && space && themeToggleStatus === "toggled" ? (
            <MarketingContextProvider>
              <Router />
            </MarketingContextProvider>
          ) : (
            <PageLoading />
          )}
        </ThemeProvider>
      </UserInfoProvider>
    </SocketProvider>
  );
};

export default App;
